import styled from 'styled-components';

import Button from '../Button';

export const StyledButton = styled(Button)`
    &.btn-small.btn-no-outline {
      align-items: center;
      border: 1px solid ${({ theme }) => theme.colors.doveGray || theme.colors.PRODUCT.PRIMARY_BORDER};
      border-radius: 6px;
      box-shadow: #17181a1f 0 1px 2px;
      color: ${({ theme }) => theme.colors.mineshaftBlack || theme.colors.PRODUCT.PRIMARY_CONTENT};
      display: flex;
      font-weight: normal;
      font-size: 1rem;
      height: 50px;
      justify-content: space-evenly;
      letter-spacing: normal;
      padding: 0;
      text-transform: none;
      width: 245px;

      &:hover {
        background: ${({ theme }) => theme.colors.PRODUCT.SECONDARY_BACKGROUND};
        color: ${({ theme }) => theme.colors.PRODUCT.PRIMARY_CONTENT} !important;
        text-decoration: none !important;
      }

      .icon-left {
        height: 1.3em;

        img {
          height: 1.3em;
        }
      }
    }
`;
