import { useMemo } from 'react';

import SelectInput from 'components/library/inputs/SelectInput';
import { useInterviewBatches } from 'hooks/queries/interview-batches';

import type { ActionMeta, OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option } from './SelectInput/types';

interface Props {
  className?: string;
  helperText?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  label?: string;
  stageInterviewId?: string;
  onChange: (newValue: OnChangeValue<Option<string>, false>, actionMeta: ActionMeta<Option<string>>) => void;
  value?: string;
}

const InterviewBatchSelectInput = ({
  className,
  helperText,
  isDisabled = false,
  isRequired = false,
  label,
  stageInterviewId,
  onChange,
  value,
}: Props) => {
  const interviewBatchesQuery = useInterviewBatches({ stage_interview_id: stageInterviewId });

  const interviewBatches = useMemo(() => {
    if (!interviewBatchesQuery.data) {
      return [];
    }
    return interviewBatchesQuery.data.interview_batches;
  }, [interviewBatchesQuery.data]);

  const options = useMemo<Option<string>[]>(() => {
    const batchOptions: Option<string>[] = interviewBatches.map((batch) => ({
      value: batch.id,
      label: batch.name,
    }));
    return batchOptions;
  }, [interviewBatches]);

  return (
    <SelectInput
      className={className}
      helperText={helperText}
      isClearable
      isDisabled={isDisabled}
      isRequired={isRequired}
      label={label}
      onChange={onChange}
      options={options}
      placeholder=""
      value={value ? options.find((option) => option.value === value) : null}
    />
  );
};

export default InterviewBatchSelectInput;
