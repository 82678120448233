import styled from 'styled-components';

import Button from 'components/library/inputs/Button';
import Table from 'components/library/data-display/Table';
import TextInput from 'components/library/inputs/TextInput';

export const StyledCandidatesPerSlotTextInput = styled(TextInput)`
  input {
    max-width: 3em;
  }
`;

export const StyledExpandedContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  padding: 1em 0;

  h6 {
    margin: 0;
    margin-bottom: .5em;
    padding-bottom: .5em;
    border-bottom: 1px solid ${({ theme }) => theme.colors.PRODUCT.SECONDARY_BORDER};
    min-width: 100%;
  }
`;

export const StyledInterviewDetails = styled.div`
  display: flex;
  flex-grow: 1;
  gap: 1em;
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

export const StyledFormRowContainer = styled.div`
  display: flex;
  gap: 1em;

  .input {
    flex: 1;
  }
`;

export const StyledSubmitButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 1em 0;
`;

export const StyledAddTimeButton = styled(Button)`
  width: max-content;
`;

export const StyledTable = styled(Table)`
  margin-top: 1em;

  tr.row-expanded-content {
    background: ${({ theme }) => theme.colors.PRODUCT.TERTIARY_BACKGROUND};
  }

  .fa-trash-can {
    color: ${({ theme }) => theme.colors.ACCENT.RED};
  }
` as typeof Table;
