import type { Application, Job, User } from '../types';
import type { UserMap } from '../hooks/queries/users';
import { startCase } from 'lodash';

export enum ResolveField {
  Email = 'email',
  Name = 'name',
}

export function resolveHiringRole (role: string, field: `${ResolveField}`, currentUser: User | undefined, job: Job | undefined, application: Application | undefined, users: UserMap): string {
  switch (role) {
    case 'requester':
    case 'scheduler':
      return currentUser?.[field] || role;
    case 'sourcer':
    case 'recruiter':
    case 'hiring_manager':
    case 'coordinator':
      let userId = application?.[`${role}_id`];
      if (!userId) {
        userId = job?.[`${role}_id`];
      }
      if (userId && users[userId]) {
        return users[userId]?.[field] || role;
      } else if (!userId) {
        // TODO: It might be nice to return whether this case happened so the
        // styling could be different.
        return `${startCase(role)} is not set for ${job?.name || application?.candidate?.name}`;
      }
      return role;
    default:
      return role;
  }
}
