import { useQuery } from 'react-query';

import InterviewPlanner from '../../libraries/interviewplanner';

import type { Candidate } from '../../types';
import type { UseQueryOptions } from 'react-query';

export enum QueryKey {
  ListCandidates = 'ListCandidates',
}

interface ListCandidatesQuery {
  ats_id?: string;
}

interface ListCandidatesData {
  candidates: Candidate[];
  total: number;
}

export const useCandidates = (query: ListCandidatesQuery = {}, options: UseQueryOptions<ListCandidatesData, Error> = {}) => {
  return useQuery<ListCandidatesData, Error>([QueryKey.ListCandidates, query], () => {
    return InterviewPlanner.request('GET', '/candidates', null, query);
  }, {
    ...options,
  });
};
