import { Route, useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { useQueryClient } from 'react-query';

import LaunchDarklyIdentifier from 'components/library/utils/LaunchDarklyProvider/LaunchDarklyIdentifier';
import { useSession } from '../../../hooks/use-session';
import { usersParams } from '../../../hooks/queries/users';

import type { RouteProps } from 'react-router-dom';
import { correctPath } from '../../../libraries/gem';

const SecureRoute = (props: RouteProps) => {
  const queryClient = useQueryClient();
  const history = useHistory();

  const {
    isSessionLoading,
    session,
  } = useSession();

  useEffect(() => {
    queryClient.prefetchQuery(usersParams({ archived: true }));
  });

  useEffect(() => {
    if (!isSessionLoading && !session) {
      const redirectUri = window.location.pathname + window.location.search;
      history.push(correctPath(`/signin?redirect=${encodeURIComponent(redirectUri)}`));
    }
  }, [history, isSessionLoading, session]);

  if (isSessionLoading || !session) {
    return null;
  }

  return (
    <LaunchDarklyIdentifier>
      <Route {...props} />
    </LaunchDarklyIdentifier>
  );
};

export default SecureRoute;
