import Moment from 'moment';
import { Breadcrumb } from 'react-breadcrumbs';
import { Helmet } from 'react-helmet-async';
import { Link, Redirect, Route, Switch, useParams } from 'react-router-dom';

import ArchiveButton from 'components/library/inputs/ArchiveButton';
import PageHeader from 'components/library/layout/PageHeader';
import Flash from 'components/library/utils/Flash';
import Label from 'components/library/utils/Label';
import LoadingSpinner from 'components/library/utils/LoadingSpinner';
import StatusIndicator from 'components/library/utils/StatusIndicator';
import { useInterviewBatch, useUpdateInterviewBatch } from 'hooks/queries/interview-batches';
import { formatDateRange } from 'libraries/formatters';
import InterviewBatchDetails from './InterviewBatchDetails';
import InterviewBatchTimes from './InterviewBatchTimes';
import { calculateTotalInterviews } from '../helpers';

import { correctPath } from 'libraries/gem';

const InterviewBatch = () => {
  const { id } = useParams<{ id: string }>();

  const {
    data: interviewBatch,
    error: interviewBatchError,
    isLoading: isInterviewBatchLoading,
  } = useInterviewBatch(id);

  const updateInterviewBatchMutation = useUpdateInterviewBatch();

  const handleArchive = () => {
    updateInterviewBatchMutation.reset();
    updateInterviewBatchMutation.mutate({ id, payload: { archived: true } });
  };

  const handleUnarchive = () => {
    updateInterviewBatchMutation.reset();
    updateInterviewBatchMutation.mutate({ id, payload: { archived: false } });
  };

  if (isInterviewBatchLoading) {
    return <LoadingSpinner />;
  }

  if (interviewBatchError) {
    return (
      <Breadcrumb
        data={{
          title: id,
          pathname: correctPath(`/app/interview-batches/${id}`),
        }}
      >
        <div>
          <Flash
            message={interviewBatchError.message}
            showFlash
            type="danger"
          />
        </div>
      </Breadcrumb>
    );
  }

  if (!interviewBatch) {
    return null;
  }

  return (
    <Breadcrumb
      data={{
        title: interviewBatch.name || id,
        pathname: correctPath(`/app/interview-batches/${id}`),
      }}
    >
      <div>
        <Helmet>
          <title>{interviewBatch.name} | Interview Batches | Gem Scheduling </title>
        </Helmet>
        <Flash
          isDismissible
          message={updateInterviewBatchMutation.error?.message}
          onDismiss={updateInterviewBatchMutation.reset}
          showFlash={updateInterviewBatchMutation.isError}
          type="danger"
        />
        <Flash
          isDismissible
          message={`Interview batch ${interviewBatch.archived ? 'archived' : 'unarchived'}.`}
          onDismiss={updateInterviewBatchMutation.reset}
          showFlash={updateInterviewBatchMutation.isSuccess}
          type="success"
        />
        <PageHeader
          details={
            <>
              <StatusIndicator color={Moment(interviewBatch.latest_interview_date).isAfter(Moment()) ? 'green' : 'gray'}>
                {formatDateRange(interviewBatch.earliest_interview_date, interviewBatch.latest_interview_date)}
              </StatusIndicator>
              <span className="separator">|</span>
              <Link to={correctPath(`/app/jobs/${interviewBatch.job_id}`)}>
                {interviewBatch.job?.name}
              </Link>
              <span className="separator">|</span>
              {interviewBatch.linked_interviews}/{calculateTotalInterviews(interviewBatch)} interviews booked
            </>
          }
          navbarLinks={[{
            label: 'Interview Times',
            location: correctPath(`/app/interview-batches/${interviewBatch.id}/times`),
          }, {
            label: 'Details',
            location: correctPath(`/app/interview-batches/${interviewBatch.id}/details`),
          }, {
            label: 'Interview History',
            location: correctPath(`/app/interview-batches/${interviewBatch.id}/interview-history`),
          }]}
          title={interviewBatch.name}
          titleIcon={interviewBatch.archived ? <Label color="gray">Archived</Label> : undefined}
        >
          <ArchiveButton
            id={id}
            isArchived={interviewBatch.archived}
            onArchive={handleArchive}
            onUnarchive={handleUnarchive}
          />
        </PageHeader>
        <Switch>
          <Redirect exact from={correctPath('/app/interview-batches/:id')} to={correctPath('/app/interview-batches/:id/times')} />
          <Route component={InterviewBatchTimes} path={correctPath('/app/interview-batches/:id/times')} />
          <Route component={InterviewBatchDetails} path={correctPath('/app/interview-batches/:id/details')} />
        </Switch>
      </div>
    </Breadcrumb>
  );
};

export default InterviewBatch;
