import Moment from 'moment-timezone';
import { useEffect, useMemo, useState } from 'react';
import { capitalize, find, flatten, uniq, uniqBy } from 'lodash';
import { useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';

import CheckboxInput from 'components/library/inputs/CheckboxInput';
import EditableSchedule from './EditableSchedule';
import Flash from 'components/library/utils/Flash';
import ScheduleTable from './ScheduleTable';
import Section from 'components/library/layout/Section';
import UnsavedEditsFlash from './UnsavedEditsFlash';
import UpdateScheduleConfirmModal from './UpdateScheduleConfirmModal';
import { ATS, directoryCalendarLabels } from 'types';
import { formatMoment, TimeFormat } from 'libraries/time';
import { resolveUsersParams, useUsersMap } from 'hooks/queries/users';
import { useAccount } from 'hooks/queries/accounts';
import { useApplication } from 'hooks/queries/applications';
import { useEvents } from 'hooks/use-events';
import { useRoomsMap } from 'hooks/queries/rooms';
import { useSchedule } from 'hooks/queries/schedules';
import { useSession } from 'hooks/use-session';

import type { ChangeEvent } from 'react';
import type { EditableSchedule as EditableScheduleType, Schedule } from '../../../../../../../types';

const ScheduleSection = () => {
  const queryClient = useQueryClient();

  const { id, scheduleId } = useParams<{ id: string; scheduleId: string }>();

  const currentUser = useSession().currentUser!;
  const account = useAccount().data;
  const application = useApplication(id).data!;
  const originalSchedule = useSchedule(scheduleId).data!;
  const rooms = useRoomsMap();
  const users = useUsersMap({ archived: true });

  const { events, fetchEvents } = useEvents();

  const [schedule, setSchedule] = useState<EditableScheduleType>(originalSchedule);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const isBatchInterview = useMemo(() => {
    return originalSchedule.interviews.some(({ interview_batch_time_slot_id }) => Boolean(interview_batch_time_slot_id));
  }, [originalSchedule.interviews]);

  const isEditable = useMemo(() => {
    const isActive = Boolean(application.active_schedules && find(application.active_schedules, ['id', scheduleId]));
    const isHeld = Boolean(application.held_schedules && find(application.held_schedules, ['id', scheduleId]));
    return !isBatchInterview && (isActive || isHeld);
  }, [application.active_schedules, application.held_schedules, scheduleId, isBatchInterview]);
  const isVideoConferencingHost = Boolean(currentUser.video_conferencing_id);
  const date = Moment(schedule.interviews[0].start_time).tz(schedule.timezone).format('YYYY-MM-DD');
  const timezone = schedule.timezone;
  const zoomHost = schedule.interviews[0].zoom_host_id;

  const zoomHostMeetings = useMemo(() => {
    if (!schedule.schedule_template.video_conferencing_enabled || account?.video_conferencing_type !== 'zoom' || !zoomHost) {
      return null;
    }
    return events[date]?.[timezone]?.zoom_hosts[zoomHost]?.meetings;
  }, [schedule.schedule_template.video_conferencing_enabled, account, events, date, timezone, zoomHost]);

  useEffect(() => {
    // This call to fetch all events for the day is needed to display conflicts
    // for the editable schedule. If we make the call here in the background,
    // before it's needed, the user doesn't have to wait for events to load
    // when switching to the edit state and it improves perceived performance.
    if (isEditable) {
      const resolvePayloads = flatten(schedule.interviews.map(({ interviewers }) => (interviewers || []).map(({ interviewer_template }) => {
        return {
          filters: interviewer_template.interviewer_filters,
          includePastInterviewers: interviewer_template.include_past_interviewers,
        };
      }))).filter((payload) => payload.filters);

      (async () => {
        const pools = await Promise.all(resolvePayloads.map(({ filters, includePastInterviewers }) => queryClient.fetchQuery(resolveUsersParams({
          applicationId: application.id,
          scheduleId: schedule.id,
          includePastInterviewers,
          interviewerFilters: filters || [],
        }))));
        const userIds = uniq([
          ...flatten(pools.map((pool) => pool.map(({ id }) => id))),
          ...flatten(schedule.interviews.map(({ interviewers }) => (interviewers || []).map(({ user_id }) => user_id).filter((id): id is string => Boolean(id)))),
        ]);
        const roomIds = schedule.interviews[0].room_id ? [schedule.interviews[0].room_id] : undefined;
        const zoomHostIds = schedule.interviews[0].zoom_host_id ? [schedule.interviews[0].zoom_host_id] : undefined;

        fetchEvents({
          date: Moment(schedule.interviews[0].start_time).tz(schedule.timezone).format('YYYY-MM-DD'),
          timezone: schedule.timezone,
          user_ids: userIds,
          room_ids: roomIds,
          zoom_host_ids: zoomHostIds,
        });
      })();
    }
  }, [schedule.id]);

  // This could cause some issues if originalSchedule gets reloaded frequently.
  // But we need to be mindful that sometimes, originalSchedule updates, and we
  // need to pull in those changes. This happens after a successful update.
  useEffect(() => {
    setSchedule(originalSchedule);
  }, [originalSchedule]);

  useEffect(() => {
    setShowConfirmModal(false);
    setIsEditing(false);
    setIsSuccess(false);
  }, [scheduleId]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setSchedule(originalSchedule);
    setIsEditing(false);
  };

  const handleIsVideoConferencingEnabledChange = (e: ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    const wasVideoConferencingEnabled = originalSchedule.schedule_template.video_conferencing_enabled;
    const oldCandidateEventLocation = originalSchedule.candidate_event_location;
    const shouldClearCandidateEventLocation = wasVideoConferencingEnabled && !isChecked;

    setSchedule((prevSchedule) => ({
      ...prevSchedule,
      schedule_template: {
        ...prevSchedule.schedule_template,
        candidate_calendar_event_template: {
          // All schedule templates attached to schedules will always have a
          // candidate calendar event template.
          ...prevSchedule.schedule_template.candidate_calendar_event_template!,
          location: shouldClearCandidateEventLocation ? '' : oldCandidateEventLocation,
        },
        video_conferencing_enabled: isChecked,
      },
      interviews: prevSchedule.interviews.map((interview) => ({
        ...interview,
        zoom_host_id: isChecked ?
          interview.zoom_host_id :
          undefined,
        zoom_host_type: isChecked ?
          interview.zoom_host_type :
          undefined,
      })),
    }));
  };

  const handleUpdateSuccess = (newlyUpdatedSchedule: Schedule) => {
    setIsSuccess(true);
    setSchedule(newlyUpdatedSchedule);
    setIsEditing(false);
  };

  const handleSave = () => {
    setShowConfirmModal(true);
  };

  const toggleConfirmModal = () => {
    setShowConfirmModal(!showConfirmModal);
  };

  const roomDeclines = originalSchedule.interviews.filter(({ room_rsvp }) => room_rsvp === 'declined').map(({ name, room_id }) => ({
    name,
    resourceId: room_id!,
    type: 'room',
  }));

  const interviewerDeclines = flatten(originalSchedule.interviews.map(({ name, interviewers }) => (interviewers || []).filter(({ rsvp }) => rsvp === 'declined').map(({ user_id }) => ({
    name,
    resourceId: user_id,
    type: 'interviewer',
  }))));

  // The Zoom host conflict warning dynamically changes in edit mode, depending on if you enable video conferencing or
  // not. This is because there's no other way to see in the calendar schedule what the conflicts are for the Zoom host.
  const zoomHostConflicts = isEditable && zoomHostMeetings ? uniqBy(flatten(schedule.interviews.map((interview) => {
    const interviewStart = Moment(interview.start_time).tz(schedule.timezone);
    const interviewEnd = Moment(interviewStart).add(interview.interview_template.duration_minutes, 'minutes');

    return zoomHostMeetings.filter((meeting) => {
      if (meeting.join_url === originalSchedule.candidate_event_location) {
        return false;
      }
      const meetingStart = Moment(meeting.start_time).tz(schedule.timezone);
      const meetingEnd = Moment(meeting.end_time).tz(schedule.timezone);
      return interviewStart.isBefore(meetingEnd) && meetingStart.isBefore(interviewEnd);
    });
  })), 'id') : [];

  return (
    <Section
      className="schedule-section"
      isEditable={isEditable}
      isEditing={isEditing}
      onCancel={handleCancel}
      onEdit={handleEdit}
      onSave={handleSave}
      title="Schedule"
    >
      <Flash
        isDismissible
        message={`Successfully updated! It may take up to a minute to see changes in ${schedule.hold ? '' : `${capitalize(account?.ats_type)} and `}${directoryCalendarLabels[account?.directory_type!]}.`}
        showFlash={isSuccess}
        type="success"
      />
      <Flash
        message={(
          <div>
            The room has declined the following invites.
            <ul>
              {roomDeclines.map((decline, i) => (
                <li key={`room-decline-${i}`}>
                  {rooms[decline.resourceId]?.name} declined <b>{decline.name}</b>.
                </li>
              ))}
            </ul>
          </div>
        )}
        showFlash={roomDeclines.length > 0}
        type="warning"
      />
      <Flash
        message={(
          <div>
            The following interviewers have declined their invites.
            <ul>
              {interviewerDeclines.map((decline, i) => (
                <li key={`interviewer-decline-${i}`}>
                  {users[decline.resourceId]?.name || users[decline.resourceId]?.email} declined <b>{decline.name}</b>.
                </li>
              ))}
            </ul>
          </div>
        )}
        showFlash={interviewerDeclines.length > 0}
        type="warning"
      />
      <Flash
        message={zoomHost && (
          <div>
            The Zoom host, <b>{schedule.interviews[0].zoom_host_type === 'room' ? rooms[zoomHost]?.name : (users[zoomHost]?.name || users[zoomHost]?.email)}</b>, is hosting other meetings during this time. The video connection will be interrupted. Please resolve the following conflicts outside InterviewPlanner.
            <ul>
              {zoomHostConflicts.map((conflict, i) => (
                <li key={`zoom-host-conflict-${i}`}>
                  {conflict.buffer_time ? 'Buffer window for ' : ''}<b>{conflict.topic}</b> at <b>{formatMoment(Moment.tz(conflict.start_time, schedule.timezone), TimeFormat.Time)}&ndash;{formatMoment(Moment.tz(conflict.end_time, schedule.timezone), TimeFormat.TimeWithTimezone)}</b>.
                </li>
              ))}
            </ul>
          </div>
        )}
        showFlash={Boolean(schedule.interviews[0].zoom_host_id && account && zoomHostConflicts.length >= account.zoom_host_concurrent_meeting_limit)}
        type="warning"
      />
      <Flash
        message={(
          <>
            This schedule contains a batch interview that cannot be edited directly. You can manage the details of the interview through the batch interview page.
          </>
        )}
        showFlash={isBatchInterview}
        type="info"
      />
      <Flash
        message={
          <>
            <p>
              This schedule is for a job that&apos;s different from the candidate&apos;s current job. This could happen if the candidate was moved to a different job in {capitalize(account?.ats_type)}.
            </p>
            <p>
              <b>You can edit this schedule, but the interviews may not show in {capitalize(account?.ats_type)}</b>.
            </p>
            {account?.ats_type === ATS.Greenhouse && schedule.interviews.some(({ scorecard_stage_interview_id }) => !scorecard_stage_interview_id) && (
              <p>
                Because of the job change, interviewers&apos; calendar events have broken scorecard links. If you would like to keep this schedule, we recommend editing the schedule and clicking on each interview to update its scorecard link.
              </p>
            )}
          </>
        }
        showFlash={isEditable && application.job_id !== schedule.stage.job_id}
        type="warning"
      />
      <Flash
        message={`${isVideoConferencingHost ? '' : 'You cannot host a video interview because you don\'t have a Zoom account. '}You must either select a Zoom host or disable video conferencing.`}
        showFlash={isEditable && schedule.schedule_template.video_conferencing_enabled && account?.video_conferencing_type === 'zoom' && Boolean(!zoomHost)}
        type="warning"
      />
      {isEditing && (
        <UnsavedEditsFlash
          newSchedule={schedule}
          originalSchedule={originalSchedule}
        />
      )}
      <CheckboxInput
        isChecked={schedule.schedule_template.video_conferencing_enabled}
        isDisabled={!isEditing}
        label="Include video conferencing links."
        onChange={handleIsVideoConferencingEnabledChange}
      />
      {isEditing ?
        <>
          <EditableSchedule
            schedule={schedule}
            setSchedule={setSchedule}
          />
          <UpdateScheduleConfirmModal
            isOpen={showConfirmModal}
            onSuccess={handleUpdateSuccess}
            onToggle={toggleConfirmModal}
            schedule={schedule}
            scheduleId={scheduleId}
          />
        </> :
        <ScheduleTable schedule={schedule} />
      }
    </Section>
  );
};

export default ScheduleSection;
