import { useMutation, useQuery, useQueryClient } from 'react-query';

import InterviewPlanner from 'libraries/interviewplanner';

import type { InterviewBatch } from 'types';
import type { UseQueryOptions } from 'react-query';

export enum QueryKey {
  RetrieveInterviewBatch = 'RetrieveInterviewBatch',
  ListInterviewBatches = 'ListInterviewBatches',
}

export const useInterviewBatch = (id?: string, options: UseQueryOptions<InterviewBatch, Error> = {}) => {
  return useQuery<InterviewBatch, Error>([QueryKey.RetrieveInterviewBatch, id], () => {
    return InterviewPlanner.request('GET', `/interview-batches/${id}`);
  }, {
    enabled: options.enabled !== undefined ? options.enabled : Boolean(id),
    ...options,
  });
};

interface ListInterviewBatchesQuery {
  limit?: number;
  offset?: number;
  name?: string;
  archived?: boolean;
  stage_interview_id?: string;
}

interface ListInterviewBatchesData {
  interview_batches: InterviewBatch[];
  total: number;
}

export const useInterviewBatches = (query: ListInterviewBatchesQuery, options: UseQueryOptions<ListInterviewBatchesData, Error> = {}) => {
  // This query can't be canceled because the promise being returned from this
  // function (which is a result of the async keyword) doesn't have a cancel
  // function on it. If we want it to be cancellable, we can't use async/await,
  // and we need to make sure the cancel function from the promise returned from
  // InterviewPlanner.request is preserved.
  return useQuery<ListInterviewBatchesData, Error>([QueryKey.ListInterviewBatches, query], async () => {
    const pageSize = 100;
    const paginatedQuery = {
      limit: pageSize,
      offset: 0,
      ...query,
    };

    const interviewBatches: InterviewBatch[] = [];
    let total = 0;
    let totalLimit: number | undefined;

    while (totalLimit === undefined || interviewBatches.length < totalLimit) {
      const response = await InterviewPlanner.request<ListInterviewBatchesData>('GET', '/interview-batches', null, paginatedQuery);
      total = response.total;
      if (response.interview_batches.length === 0) {
        break;
      }

      interviewBatches.push(...response.interview_batches);
      totalLimit = query.limit! < total ? query.limit : total;
      paginatedQuery.offset = paginatedQuery.offset + paginatedQuery.limit;
    }

    return { interview_batches: interviewBatches, total };
  }, options);
};

export interface CreateInterviewBatchPayload {
  name: string;
  job_id: string;
  times: {
    start_time: string;
    end_time: string;
    timezone: string;
    stage_interview_id: string;
    interview_template: {
      duration_minutes: number;
      interviewer_templates: {
        description?: string;
        optional: boolean;
        include_past_interviewers?: boolean;
        interviewer_filters: {
          interviewer_filter_expressions: {
            negated: boolean;
            filterable_id: string;
            filterable_type: string;
          }[];
        }[];
      }[];
    };
    candidates_per_slot: number;
    zoom_host_id?: string;
    zoom_host_type?: string;
  }[];
}

interface CreateInterviewBatchMutationVariables {
  payload: CreateInterviewBatchPayload;
}

export const useCreateInterviewBatch = () => {
  const queryClient = useQueryClient();

  return useMutation<InterviewBatch, Error, CreateInterviewBatchMutationVariables>(({ payload }) => {
    return InterviewPlanner.request('POST', '/interview-batches', payload);
  }, {
    onSuccess: (data: InterviewBatch) => {
      queryClient.invalidateQueries([QueryKey.ListInterviewBatches]);
      queryClient.setQueryData([QueryKey.RetrieveInterviewBatch, { id: data.id }], data);
    },
  });
};

export interface UpdateInterviewBatchPayload {
  archived?: boolean;
  name?: string;
  job_id?: string;
  times?: {
    id?: string; // Used to indicate updating an interview batch time.
    stage_interview_id?: string;
    start_time?: string;
    end_time?: string;
    zoom_host_id?: string;
    zoom_host_type?: string;
  }[];
}

interface UpdateInterviewBatchMutationVariables {
  id: string;
  payload?: UpdateInterviewBatchPayload;
}

export const useUpdateInterviewBatch = () => {
  const queryClient = useQueryClient();

  return useMutation<InterviewBatch, Error, UpdateInterviewBatchMutationVariables>(({ id, payload }) => {
    return InterviewPlanner.request('POST', `/interview-batches/${id}`, payload, null, false);
  }, {
    onSuccess: (data: InterviewBatch) => {
      queryClient.invalidateQueries([QueryKey.ListInterviewBatches]);
      queryClient.setQueryData([QueryKey.RetrieveInterviewBatch, { id: data.id }], data);
    },
  });
};
