import { StyledDateRangeInput } from './styles';

import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option as DateRangeOption } from 'components/library/inputs/DateRangeInput/types';

interface Props {
  helperText?: string;
  isClearable?: boolean;
  label?: string;
  onChange: (value: string) => void;
  value: string;
}

const DateInput = ({
  helperText,
  isClearable,
  label,
  onChange,
  value,
}: Props) => {
  return (
    <StyledDateRangeInput
      helperText={helperText}
      isAlwaysCustom
      isClearable={isClearable}
      label={label}
      onChange={(option: OnChangeValue<DateRangeOption, false>) => onChange(option?.startDate || '')}
      placeholder=""
      selectedDateRange={{
        value: 'custom',
        startDate: value,
        endDate: value,
      }}
    />
  );
};

export default DateInput;
