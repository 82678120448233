import { Breadcrumb } from 'react-breadcrumbs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Route, Switch } from 'react-router-dom';
import { faUsers } from '@fortawesome/free-solid-svg-icons';

import InterviewBatch from './InterviewBatch';
import InterviewBatchCreate from './InterviewBatchCreate';
import InterviewBatchList from './InterviewBatchList';
import { correctPath } from 'libraries/gem';

const InterviewBatches = () => (
  <Breadcrumb
    data={{
      title: <><FontAwesomeIcon icon={faUsers} />Interview Batches</>,
      pathname: correctPath('/app/interview-batches'),
    }}
  >
    <Switch>
      <Route component={InterviewBatchList} exact path={correctPath('/app/interview-batches')} />
      <Route component={InterviewBatchCreate} path={correctPath('/app/interview-batches/create')} />
      <Route component={InterviewBatch} path={correctPath('/app/interview-batches/:id')} />
    </Switch>
  </Breadcrumb>
);

export default InterviewBatches;
