import { find, isEmpty } from 'lodash';
import { useCallback, useMemo } from 'react';

import DurationInput from '../../../../../../library/inputs/DurationInput';
import FeedbackFormSelectInput from '../../../../../../library/inputs/FeedbackFormSelectInput';
import ListItem from '../../../../../../library/data-display/ListItem';
import LoadingSpinner from '../../../../../../library/utils/LoadingSpinner';
import SelectInput from '../../../../../../library/inputs/SelectInput';
import Table from '../../../../../../library/data-display/Table';
import { formatDuration } from '../../../../../../../libraries/formatters';
import { useResolvePools, useUsersMap } from '../../../../../../../hooks/queries/users';
import { useSession } from '../../../../../../../hooks/use-session';

import type { Dispatch, SetStateAction } from 'react';
import type { EditableSelfSchedulingLink } from '../../../../../../../types';
import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option } from '../../../../../../library/inputs/SelectInput/types';
import type { TableSchema } from '../../../../../../library/data-display/Table';
import InterviewerTemplatesForm from 'components/library/inputs/InterviewerTemplatesForm';
import type { CreateInterviewTemplatePayload } from 'hooks/queries/interview-templates';
import { constructInterviewerTemplatesForm } from '../../../CandidateRequestSelfScheduling/helpers';

interface Props {
  isEditing: boolean;
  selfSchedulingLink: EditableSelfSchedulingLink;
  setSelfSchedulingLink: Dispatch<SetStateAction<EditableSelfSchedulingLink>>;
  applicationId?: string;
}

const ScheduleTable = ({ isEditing, selfSchedulingLink, setSelfSchedulingLink, applicationId }: Props) => {
  const { account } = useSession();
  const users = useUsersMap({ archived: true });

  const poolsQueries = useResolvePools({
    applicationId: selfSchedulingLink.application_id,
    scheduleId: selfSchedulingLink.schedule?.id,
    includePastInterviewers: selfSchedulingLink.interview_template.interviewer_templates?.[0].include_past_interviewers ?? true,
    pools: (selfSchedulingLink.interview_template.interviewer_templates || []).map(({ interviewer_filters }) => interviewer_filters || []),
  });
  const poolsAreLoading = useMemo(() => poolsQueries.some(({ isLoading }) => isLoading), [poolsQueries]);

  const stageInterviewOptions = useMemo<Option<string>[]>(() => {
    return (selfSchedulingLink.stage.stage_interviews || []).map((stageInterview) => ({
      value: stageInterview.id,
      label: stageInterview.name,
    }));
  }, [selfSchedulingLink.stage.stage_interviews]);

  const handleDurationChange = useCallback((duration: number) => {
    setSelfSchedulingLink((prevState) => ({
      ...prevState,
      interview_template: {
        ...prevState.interview_template,
        duration_minutes: duration,
      },
    }));
  }, []);

  const handleStageInterviewChange = useCallback((option: OnChangeValue<Option<string>, false>) => {
    setSelfSchedulingLink((prevState) => ({
      ...prevState,
      // This isn't clearable, so option should always be defined.
      stage_interview_id: option!.value,
    }));
  }, []);

  const handleFeedbackFormChange = useCallback((option: OnChangeValue<Option<string>, false>) => {
    setSelfSchedulingLink((prevState) => ({
      ...prevState,
      feedback_form_id: option ? option.value : '',
    }));
  }, []);

  const handleInterviewersChange = useCallback((templates: CreateInterviewTemplatePayload['interviewer_templates']) => {
    setSelfSchedulingLink((prevState) => ({
      ...prevState,
      interview_template: {
        ...prevState.interview_template,
        interviewer_templates: templates,
      },
    }));
  }, []);

  const schema = useMemo<TableSchema<EditableSelfSchedulingLink>>(() => [{
    header: 'Duration',
    displayValue: ({ interview_batch_id, interview_template: { duration_minutes } }) => interview_batch_id ? <i>Based on interview batch</i> : formatDuration(duration_minutes),
    displayEditValue: ({ interview_batch_id, interview_template: { duration_minutes } }) => (
      interview_batch_id ?
        <i>Based on interview batch</i> :
        <DurationInput
          isAbbreviatedUnits
          isRequired
          onChange={handleDurationChange}
          value={duration_minutes}
        />
    ),
  }, {
    header: 'Interview',
    displayValue: ({ stage_interview: { name } }) => name,
    displayEditValue: ({ stage_interview_id }) => (
      <SelectInput
        isRequired
        onChange={handleStageInterviewChange}
        options={stageInterviewOptions}
        value={find(stageInterviewOptions, ['value', stage_interview_id])}
      />
    ),
  }, account?.ats_type === 'lever' && {
    header: 'Feedback Form',
    displayValue: ({ feedback_form }) => {
      if (!feedback_form) {
        return <span className="no-template">No feedback form</span>;
      }
      return feedback_form.name;
    },
    displayEditValue: ({ id, feedback_form_id }) => (
      <FeedbackFormSelectInput
        name={id}
        onChange={handleFeedbackFormChange}
        selectedFeedbackFormId={feedback_form_id}
      />
    ),
  }, {
    header: 'Interview Panel',
    displayValue: ({ interview_batch_id, interview_template: { interviewer_templates } }) => {
      if (interview_batch_id) {
        return <i>Based on interview batch</i>;
      }
      if (poolsAreLoading) {
        return <LoadingSpinner />;
      }
      return isEmpty(interviewer_templates) ?
        <ListItem label={<i>No interviewers</i>} /> :
        <InterviewerTemplatesForm
          applicationId={applicationId}
          interviewerTemplates={constructInterviewerTemplatesForm(interviewer_templates ?? [])}
          isAlwaysSingleInterviewer
          isDisabled
          setInterviewerTemplates={() => {}}
        />;
    },
    displayEditValue: ({ interview_batch_id, interview_template: { interviewer_templates } }) => (
      interview_batch_id ?
        <i>Based on interview batch</i> :
        <InterviewerTemplatesForm
          applicationId={applicationId}
          interviewerTemplates={constructInterviewerTemplatesForm(interviewer_templates ?? [])}
          isAlwaysSingleInterviewer
          setInterviewerTemplates={handleInterviewersChange}
        />
    ),
  }], [
    account?.ats_type,
    handleFeedbackFormChange,
    handleStageInterviewChange,
    poolsAreLoading,
    poolsQueries,
    users,
  ]);

  return (
    <Table
      className="self-scheduling-link-request-table"
      data={[selfSchedulingLink]}
      isEditing={isEditing}
      layout="vertical"
      schema={schema}
    />
  );
};

export default ScheduleTable;
