import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import ActionBar from './ActionBar';
import Banner from '../library/utils/Banner';
import Button from '../library/inputs/Button';
import CalendarEventTemplates from './CalendarEventTemplates';
import Candidates from './Candidates';
import Company from './Company';
import EmailTemplates from './EmailTemplates';
import HiringMeetingTemplates from './HiringMeetingTemplates';
import Integrations from './Integrations';
import InterviewBatches from './InterviewBatches';
import InterviewTemplates from './InterviewTemplates';
import InterviewerReports from './InterviewerReports';
import Interviewers from './Interviewers';
import Jobs from './Jobs';
import Menu from './Menu';
import Modal from '../library/layout/Modal';
import NewVersionBanner from '../library/utils/NewVersionBanner';
import NotFound from './ApplicationNotFound';
import Overview from './Overview';
import ReauthBanner from 'components/library/utils/ReauthBanner';
import Rooms from './Rooms';
import Tasks from './Tasks';
import TrainingPrograms from './TrainingPrograms';
import User from './User';
import useSavedQueryParams from '../../hooks/use-saved-query-params';
import Welcome from './Welcome';
import { EventsProvider } from '../../hooks/use-events';
import { useLDFlags } from '../../hooks/use-ld-flags';
import { useSession } from '../../hooks/use-session';
import { correctPath } from '../../libraries/gem';

const Application = () => {
  const history = useHistory();

  const { batchScheduling, hideTaskBadge } = useLDFlags();

  const { account, currentUser } = useSession();

  const [savedOverviewParams] = useSavedQueryParams('/app/overview');

  const [showModal, setShowModal] = useState(false);

  const autoCollapseMenu = useMediaQuery({ query: '(max-width: 900px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 750px)' });
  const [isMenuExpanded, setIsMenuExpanded] = useState(!autoCollapseMenu);

  // Hide menu when screen is resized to < 900 px
  // or when user is navigating from Gem
  useEffect(() => {
    setIsMenuExpanded(!autoCollapseMenu || document.referrer.includes('gem.com'));
  }, [autoCollapseMenu]);

  // Hide menu when navigating to a new route on mobile
  useEffect(() => history.listen(() => {
    isMobile && setIsMenuExpanded(false);
  }));

  const toggleIsMenuExpanded = () => {
    setIsMenuExpanded(!isMenuExpanded);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const demoLink = account?.demo && window.location.origin + correctPath(`/demo?name=${currentUser?.name}&email=${currentUser?.email.replace('+interviewplanner-demo', '').replace('+', '%2B')}&company=${account.name}`.replace(' ', '+'));

  return (
    <EventsProvider>
      <div className="application">
        {account?.demo && (
          <Banner>
            This is a sandbox for <b>{currentUser?.name} ({currentUser?.email.replace('+interviewplanner-demo', '')})</b> at <b>{account?.name}</b>.
            &nbsp;
            <Button
              className="btn-link"
              color="no-outline"
              onClick={toggleModal}
              size="small"
              value="What's a sandbox?"
            />
            <Modal
              cancelButtonValue="Ok, got it!"
              isOpen={showModal}
              onToggle={toggleModal}
              showSubmitButton={false}
              title="What's a sandbox?"
            >
              <p>
                A sandbox is a demo account with fake data. This is for you to get a feel for how InterviewPlanner works, without having to integrate your ATS or calendar system.
              </p>
              <p>
                You can <b>create interview templates</b>, <b>tag interviewers with properties and interview eligibilities</b>, <b>request availability</b>, and <b>schedule candidates</b>&mdash;just like in the real app! When you schedule a candidate in the sandbox, we (the InterviewPlanner demo calendar) will send you calendar invites for both the individual interview events and the candidate calendar event. In the real scheduling workflow, these would be sent from your team&apos;s scheduling calendar to the interviewers and the candidate, respectively.
              </p>
              <p>
                To share your sandbox with someone else on your team, just send them this link:
              </p>
              <pre>
                {demoLink}
              </pre>
              <p>
                As you play with InterviewPlanner, we&apos;re happy to answer any questions you have via live chat! We look forward to your feedback.
              </p>
            </Modal>
          </Banner>
        )}
        <NewVersionBanner />
        <ReauthBanner />
        <div className="app-container">
          <Menu
            isExpanded={isMenuExpanded}
            toggleIsExpanded={toggleIsMenuExpanded}
          />
          <div className="app-bar-container">
            <ActionBar
              isMenuExpanded={isMenuExpanded}
              toggleIsMenuExpanded={toggleIsMenuExpanded}
            />
            <div className="content-container">
              <Switch>
                <Redirect exact from={correctPath('/app')} to={correctPath(`/app/overview${savedOverviewParams}`)} />
                <Route component={Overview} path={correctPath('/app/overview')} />
                {!hideTaskBadge && <Route component={Tasks} path={correctPath('/app/tasks')} />}
                <Route component={Candidates} path={correctPath('/app/candidates')} />
                {batchScheduling && <Route component={InterviewBatches} path={correctPath('/app/interview-batches')} />}
                <Route component={InterviewerReports} path={correctPath('/app/interviewer-reports')} />
                <Route component={Jobs} path={correctPath('/app/jobs')} />
                <Route component={CalendarEventTemplates} path={correctPath('/app/calendar-event-templates')} />
                <Route component={InterviewTemplates} path={correctPath('/app/interview-templates')} />
                <Route component={HiringMeetingTemplates} path={correctPath('/app/hiring-meeting-templates')} />
                <Route component={EmailTemplates} path={correctPath('/app/email-templates')} />
                <Route component={Interviewers} path={correctPath('/app/interviewers')} />
                <Route component={TrainingPrograms} path={correctPath('/app/training-programs')} />
                <Route component={Rooms} path={correctPath('/app/rooms')} />
                <Route component={Company} path={correctPath('/app/company')} />
                <Route component={Integrations} path={correctPath('/app/integrations')} />
                <Route component={User} path={correctPath('/app/user')} />
                <Route component={Welcome} path={correctPath('/app/welcome')} />
                <Route component={NotFound} path={correctPath('/')} />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </EventsProvider>
  );
};

export default Application;
