import { Link } from 'react-router-dom';
import { useEffect } from 'react';

import EditorInput from '../inputs/EditorInput';
import EmailPreview from './EmailPreview';
import Flash from '../utils/Flash';
import LoadingSpinner from '../utils/LoadingSpinner';
import Token from '../inputs/EditorInput/Token';
import TokenizedString from './TokenizedString';
import { EditorType, ElementType, EmailTemplateType, Token as TokenType } from '../../../types';
import { ResolveField, resolveHiringRole } from '../../../libraries/email';
import { htmlToSlateValue } from '../../../libraries/editor/html-to-slate-value';
import { slateValueToText } from '../../../libraries/editor/slate-value-to-text';
import { textToSlateValue } from '../../../libraries/editor/text-to-slate-value';
import { translateSlateValueBetweenEditorTypes } from '../../../libraries/editor/translate-slate-value-between-editor-types';
import { useApplication } from '../../../hooks/queries/applications';
import { useEmailTemplate } from '../../../hooks/queries/email-templates';
import { useJob } from '../../../hooks/queries/jobs';
import { useSession } from '../../../hooks/use-session';
import { useSlateEditor } from '../../../hooks/use-slate-editor';
import { useUsersMap } from '../../../hooks/queries/users';

import type { EditorAttachment, TokensResponse } from '../../../types';
import { correctPath } from 'libraries/gem';

interface Props {
  // Either applicationId or jobId is required.
  applicationId?: string;
  // Either emailTemplate or id is required.
  emailTemplate?: {
    attachments?: EditorAttachment[];
    bcc_emails?: string[];
    body?: string;
    cc_emails?: string[];
    sender_email: string;
    sender_name: string;
    subject: string;
    type: EmailTemplateType;
  };
  id?: string;
  jobId?: string;
  pendingPreviewMessage: string;
  showFlash?: boolean;
  tokens?: TokensResponse;
  // This is used for confirmation emails only because we use this to convert
  // between single-block and multi-block.
  type?: `${EditorType.ConfirmationEmail | EditorType.MultiBlockConfirmationEmail}`;
}

const EmailTemplateSummary = ({
  applicationId,
  emailTemplate: emailTemplateFromProps,
  id,
  jobId,
  pendingPreviewMessage,
  showFlash = false,
  tokens,
  type,
}: Props) => {
  const {
    data: emailTemplateFromId,
    isLoading: isEmailTemplateLoading,
  } = useEmailTemplate(id);
  const emailTemplate = emailTemplateFromId || emailTemplateFromProps;

  const {
    data: job,
    isLoading: isJobLoading,
  } = useJob(jobId);

  const {
    data: application,
    isLoading: isApplicationLoading,
  } = useApplication(applicationId);

  const { currentUser } = useSession();
  const users = useUsersMap({ archived: true });

  const [slateEditor, slateValue, setSlateValue, setValue] = useSlateEditor(emailTemplate?.body || '');

  useEffect(() => {
    if (emailTemplate?.body) {
      if (emailTemplate.type === EmailTemplateType.ConfirmationEmail && type) {
        setSlateValue(translateSlateValueBetweenEditorTypes(htmlToSlateValue(emailTemplate.body), emailTemplate.type, type));
      } else {
        setValue(emailTemplate.body);
      }
    }
  }, [emailTemplate?.body, emailTemplate?.type, type]);

  if (
    !currentUser ||
    !(job || application) ||
    isEmailTemplateLoading ||
    isJobLoading ||
    isApplicationLoading ||
    !emailTemplate
  ) {
    return <LoadingSpinner />;
  }

  return (
    <div className="email-template-summary">
      <EmailPreview
        attachments={emailTemplate.attachments}
        bccEmails={(emailTemplate.bcc_emails || []).map((email) => resolveHiringRole(email, ResolveField.Email, currentUser, job, application, users))}
        body={emailTemplate.body &&
          <EditorInput
            editor={slateEditor}
            isDisabled
            pendingPreviewMessage={pendingPreviewMessage}
            showToolbar={false}
            tokens={tokens}
            type={type || emailTemplate.type}
            value={slateValue}
          />
        }
        ccEmails={(emailTemplate.cc_emails || []).map((email) => resolveHiringRole(email, ResolveField.Email, currentUser, job, application, users))}
        senderEmail={resolveHiringRole(emailTemplate.sender_email, ResolveField.Email, currentUser, job, application, users)}
        senderName={resolveHiringRole(emailTemplate.sender_name, ResolveField.Name, currentUser, job, application, users)}
        subject={<TokenizedString
          pendingPreviewMessage={pendingPreviewMessage}
          tokens={tokens}
          type={type || emailTemplate.type}
          value={(
            emailTemplate.type === EmailTemplateType.ConfirmationEmail && type ?
              slateValueToText(
                translateSlateValueBetweenEditorTypes(
                  textToSlateValue(emailTemplate.subject),
                  EditorType.ConfirmationEmail,
                  type
                )
              ) :
              emailTemplate.subject
          )}
        />}
        to={<Token
          element={{ token: TokenType.CandidateEmail, type: ElementType.Token, children: [{ text: '' }] }}
          pendingPreviewMessage={pendingPreviewMessage}
          tokens={{
            [TokenType.CandidateEmail]: {
              pending: true,
              disabled: false,
            },
          } as TokensResponse}
          type={type || emailTemplate.type}
        />}
      />
      <Flash
        message={<span>This template is linked to other stages. You can edit its details from its <Link to={correctPath(`/app/email-templates/${id}`)}>email template page</Link>.</span>}
        showFlash={showFlash}
        type="info"
      />
    </div>
  );
};

export default EmailTemplateSummary;
