import { useEffect, useState } from 'react';
import { Breadcrumb } from 'react-breadcrumbs';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

import Section from 'components/library/layout/Section';
import { useInterviewBatch } from 'hooks/queries/interview-batches';
import { correctPath } from 'libraries/gem';
import InterviewBatchTimesTable from './InterviewBatchTimesTable';
import { StyledTabContainer } from '../styles';

import type { InterviewBatchTime } from 'types';

const InterviewBatchTimes = () => {
  const { id: interviewBatchId } = useParams<{ id: string }>();

  const [times, setTimes] = useState<InterviewBatchTime[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const interviewBatch = useInterviewBatch(interviewBatchId).data!;

  useEffect(() => {
    if (interviewBatch) {
      setTimes(interviewBatch.times);
    }
  }, [interviewBatch]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setTimes(interviewBatch.times);
    setIsEditing(false);
  };

  const handleSave = async () => {
    setIsSaving(true);
    // TODO: Save times
    setIsSaving(false);
  };

  return (
    <Breadcrumb
      data={{
        title: 'Interview Times',
        pathname: correctPath(`/app/interview-batches/${interviewBatchId}/times`),
      }}
    >
      <StyledTabContainer>
        <Helmet>
          <title>{interviewBatch.name} | Times | Interview Batches | Gem Scheduling</title>
        </Helmet>
        <Section
          isEditable
          isEditing={isEditing}
          isSaving={isSaving}
          onCancel={handleCancel}
          onEdit={handleEdit}
          onSave={handleSave}
          title="Interview times"
        >
          <InterviewBatchTimesTable
            isEditing={isEditing}
            times={times}
            totalCount={times.length}
          />
        </Section>
      </StyledTabContainer>
    </Breadcrumb>
  );
};

export default InterviewBatchTimes;
