import { useMutation, useQuery, useQueryClient } from 'react-query';

import InterviewPlanner from '../../libraries/interviewplanner';

import type { Application } from '../../types';
import type { HiringTeamRole } from '../../types';
import type { UseQueryOptions } from 'react-query';

export enum QueryKey {
  ListApplications = 'ListApplications',
  RetrieveApplication = 'RetrieveApplication',
}

export const useApplication = (id?: string, options: UseQueryOptions<Application, Error> = {}) => {
  return useQuery<Application, Error>([QueryKey.RetrieveApplication, id], () => {
    return InterviewPlanner.request('GET', `/applications/${id}`);
  }, {
    enabled: options.enabled !== undefined ? options.enabled : Boolean(id),
    ...options,
  });
};

interface ListApplicationsQuery {
  archived?: boolean;
  ats_id?: string;
  candidate_name_or_email?: string;
  job?: string;
  limit?: number;
  offset?: number;
  scheduling_status?: string[];
  assigned_user?: string;
  including_user?: string;
  including_role?: HiringTeamRole[];
}

interface ListApplicationsData {
  applications: Application[];
  total: number;
}

export const useApplications = (query: ListApplicationsQuery = {}, options: UseQueryOptions<ListApplicationsData, Error> = {}) => {
  return useQuery<ListApplicationsData, Error>([QueryKey.ListApplications, query], () => {
    return InterviewPlanner.request('GET', '/applications', null, query);
  }, {
    ...options,
  });
};

interface UpdateApplicationPayload {
  archived?: boolean;
}

interface UpdateApplicationMutationVariables {
  id: string;
  payload?: UpdateApplicationPayload;
}

export const useUpdateApplication = () => {
  const queryClient = useQueryClient();

  return useMutation<Application, Error, UpdateApplicationMutationVariables>(({ id, payload }) => {
    return InterviewPlanner.request('POST', `/applications/${id}`, payload);
  }, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([QueryKey.ListApplications]);
      queryClient.setQueryData([QueryKey.RetrieveApplication, data.id], data);
    },
  });
};
