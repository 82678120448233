import { useEffect } from 'react';
import { Breadcrumb } from 'react-breadcrumbs';
import { Redirect } from 'react-router-dom';

import CandidateSyncDelayMessage from 'components/Application/Candidates/CandidateSyncDelayMessage';
import LoadingSpinner from '../../library/utils/LoadingSpinner';
import useQueryState from '../../../hooks/use-query-state';
import { ATS } from '../../../types';
import { useApplications } from '../../../hooks/queries/applications';
import { useCandidates } from '../../../hooks/queries/candidates';
import { useSession } from '../../../hooks/use-session';
import { correctPath } from 'libraries/gem';

const atsUsingApplications = [ATS.Gem, ATS.Greenhouse];
const atsUsingCandidates = [ATS.Lever];

const CandidateATSRedirect = () => {
  const [id] = useQueryState<string>('id', '');

  const { account } = useSession();

  const {
    data: applications,
    isLoading: isApplicationsLoading,
    refetch: refetchApplications,
  } = useApplications({ ats_id: id }, {
    enabled: Boolean(id && account?.ats_type && atsUsingApplications.includes(account.ats_type)),
  });
  const {
    data: candidates,
    isLoading: isCandidatesLoading,
    refetch: refetchCandidates,
  } = useCandidates({ ats_id: id }, {
    enabled: Boolean(id && account?.ats_type && atsUsingCandidates.includes(account.ats_type)),
  });

  const candidateWasFound = (applications?.applications && applications?.applications?.length > 0) || (candidates?.candidates && candidates?.candidates?.length > 0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!candidateWasFound) {
        refetchApplications();
        refetchCandidates();
      }
    }, 1000);
    if (candidateWasFound) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [candidateWasFound]);

  if (!id) {
    return <Redirect to={correctPath('/app/candidates')} />;
  }

  if (isApplicationsLoading || isCandidatesLoading) {
    return <LoadingSpinner />;
  }

  if (applications?.applications && applications.applications.length > 0) {
    analytics.track('ATS Redirect Succeeded', { id });
    return <Redirect to={correctPath(`/app/candidates/${applications.applications[0].id}`)} />;
  }

  if (candidates?.candidates && candidates.candidates.length > 0 && candidates.candidates[0].applications?.length > 0) {
    analytics.track('ATS Redirect Succeeded', { id });
    return <Redirect to={correctPath(`/app/candidates/${candidates.candidates[0].applications[0].id}`)} />;
  }

  analytics.track('ATS Redirect Failed', { id });

  return (
    <Breadcrumb
      data={{
        title: id,
        pathname: correctPath('/app/candidates/ats'),
      }}
    >
      <CandidateSyncDelayMessage />
    </Breadcrumb>
  );
};

export default CandidateATSRedirect;
