import Moment from 'moment-timezone';
import { Breadcrumb } from 'react-breadcrumbs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, Redirect, Route, Switch, useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { capitalize, find, isEmpty } from 'lodash';
import { faShuffle, faUser, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { useMediaQuery } from 'react-responsive';
import { useEffect, useMemo, useState } from 'react';

import CandidateSyncDelayMessage from 'components/Application/Candidates/CandidateSyncDelayMessage';
import ArchiveButton from '../../../library/inputs/ArchiveButton';
import AssignCandidateModal from './AssignCandidateModal';
import Button from '../../../library/inputs/Button';
import CancelAvailabilityButton from '../CancelAvailabilityButton';
import CancelMeetingButton from '../CancelMeetingButton';
import CancelSelfSchedulingLinkButton from '../CancelSelfSchedulingLinkButton';
import CandidateActionButton from '../CandidateActionButton';
import CandidateAssignmentBanner from './CandidateAssignmentBanner';
import CandidateAvailabilities from './CandidateAvailabilities';
import CandidateDetails from './CandidateDetails';
import CandidateEmails from './CandidateEmails';
import CandidateHiringMeetingCreate from './CandidateHiringMeetingCreate';
import CandidateHiringMeetings from './CandidateHiringMeetings';
import CandidateManualSchedule from './CandidateManualSchedule';
import CandidateRequestAvailability from './CandidateRequestAvailability';
import CandidateRequestSelfScheduling from './CandidateRequestSelfScheduling';
import CandidateScheduleCreate from './CandidateScheduleCreate';
import CandidateSchedules from './CandidateSchedules';
import CandidateSubmitAvailability from './CandidateSubmitAvailability';
import Flash from '../../../library/utils/Flash';
import Label from '../../../library/utils/Label';
import LoadingSpinner from '../../../library/utils/LoadingSpinner';
import OpenInATSButton from '../../../library/inputs/OpenInATSButton';
import OpenInChatButton from '../../../library/inputs/OpenInChatButton';
import PageHeader from '../../../library/layout/PageHeader';
import PlayAudioButton from '../../../library/data-display/PlayAudioButton';
import SchedulingStatusIndicator from '../../../library/utils/SchedulingStatusIndicator';
import Tooltip from '../../../library/utils/Tooltip';
import useQueryState from '../../../../hooks/use-query-state';
import { ATS, atsLabels, FavoriteResourceType } from '../../../../types';
import { formatMoment, TimeFormat } from '../../../../libraries/time';
import { formatPronouns } from '../../../../libraries/formatters';
import { useApplication, useApplications, useUpdateApplication } from '../../../../hooks/queries/applications';
import { useCandidates } from '../../../../hooks/queries/candidates';
import { useStageInterviews } from '../../../../hooks/queries/stage-interviews';
import { useLDFlags } from '../../../../hooks/use-ld-flags';
import { useSession } from '../../../../hooks/use-session';

import type { ReactNode } from 'react';
import { correctPath } from 'libraries/gem';

const atsUsingApplications = [ATS.Gem, ATS.Greenhouse];
const atsUsingCandidates = [ATS.Lever];

const Candidate = () => {
  const history = useHistory();

  const { id } = useParams<{ id: string }>();

  const [idType] = useQueryState<string>('id_type', '');
  const [interviewId] = useQueryState<string>('interview', '');

  const { hash, pathname } = useLocation();

  const { account } = useSession();

  const { applicationAssignees, manualSchedulingFlow } = useLDFlags();

  const isInHiringMeetingFlow = useRouteMatch({
    path: correctPath('/app/candidates/:id/schedule-hiring-meeting'),
  });
  const isInSubmitAvailabilityFlow = useRouteMatch({
    path: correctPath('/app/candidates/:id/submit-availability'),
  });
  const isInRequestAvailabilityFlow = useRouteMatch({
    path: correctPath('/app/candidates/:id/request-availability'),
  });
  const isInRequestSelfSchedulingFlow = useRouteMatch({
    path: correctPath('/app/candidates/:id/self-schedule'),
  });
  const isInSchedulingFlow = useRouteMatch({
    path: correctPath('/app/candidates/:id/schedule'),
  });
  const isInManualSchedulingFlow = useRouteMatch({
    path: '/app/candidates/:id/manual-schedule',
  });
  const availabilityPageMatch = useRouteMatch<{ id: string; availabilityId: string }>({
    path: correctPath('/app/candidates/:id/availabilities/:availabilityId'),
  });
  const schedulePageMatch = useRouteMatch<{ id: string; scheduleId: string }>({
    path: correctPath('/app/candidates/:id/schedules/:scheduleId'),
  });
  const availabilityId = availabilityPageMatch?.params.availabilityId;
  const scheduleId = schedulePageMatch?.params.scheduleId;

  const applicationQuery = useApplication(id, { enabled: idType !== 'ats' });
  const application = applicationQuery.data;

  const updateApplicationMutation = useUpdateApplication();

  const isSmallScreen = useMediaQuery({ query: '(max-width: 1000px)' });

  const [actionButtonSuccessFlashMessage, setActionButtonSuccessFlashMessage] = useState<ReactNode>(null);
  const [actionButtonErrorFlashMessage, setActionButtonErrorFlashMessage] = useState<ReactNode>(null);
  const [clickedCancelScheduleButton, setClickedCancelScheduleButton] = useState(false);
  const [clickedCancelSelfSchedulingLinkButton, setClickedCancelSelfSchedulingLinkButton] = useState(false);
  const [clickedCancelAvailabilityButton, setClickedCancelAvailabilityButton] = useState(false);
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);

  const handleArchive = () => {
    updateApplicationMutation.reset();
    updateApplicationMutation.mutate({ id, payload: { archived: true } });
  };

  const handleUnarchive = () => {
    updateApplicationMutation.reset();
    updateApplicationMutation.mutate({ id, payload: { archived: false } });
  };

  const handleCancelScheduleSuccess = (cancelledScheduleIds: string[]) => {
    setClickedCancelScheduleButton(true);
    const isHeldSchedule = Boolean(scheduleId && find(application?.held_schedules, ['id', scheduleId]));
    // This currently does not work because when we redirect to the candidate
    // page, the candidate hasn't reloaded yet, so when it checks if it should
    // redirect to a schedule, it finds the one that we just cancelled and then
    // brings us back. Ideally, when you land on that page, it's smart enough to
    // redirect them out, but the weirdness with self-scheduling links makes
    // that hard.
    if (isHeldSchedule && scheduleId && cancelledScheduleIds.includes(scheduleId)) {
      // If we're on the page of the cancelled schedule and that schedule is a
      // held schedule, it's no longer going to show up in all_schedules, so we
      // need to redirect away from that page.
      history.push(correctPath(`/app/candidates/${id}`));
    }
  };

  const handleCancelSelfSchedulingLinkSuccess = () => {
    setClickedCancelSelfSchedulingLinkButton(true);
  };

  const handleCancelAvailabilitySuccess = () => {
    setClickedCancelAvailabilityButton(true);
  };

  const handleDismissSuccess = () => {
    setClickedCancelScheduleButton(false);
    setClickedCancelAvailabilityButton(false);
  };

  const hasAvailabilities = Boolean(application && (application.active_availabilities || application.all_availabilities) && (!isEmpty(application.active_availabilities) || (application.all_availabilities || []).length > 0));
  const hasHiringMeetings = !isEmpty(application?.all_hiring_meetings);
  const hasSchedules = Boolean(application && (application.all_schedules || application.held_schedules) && (application.all_schedules || []).length + (application.held_schedules || []).length > 0);
  const hasActiveSchedules = Boolean(application && (application.active_schedules || application.held_schedules) && (application.active_schedules || []).length + (application.held_schedules || []).length > 0);
  const hasSelfSchedulingLinks = Boolean(application?.all_self_scheduling_links && application.all_self_scheduling_links.length > 0);
  const hasRequestedSelfSchedulingLinks = Boolean(application?.all_self_scheduling_links && application.all_self_scheduling_links.map((link) => link.status === 'requested').length > 0);
  const canSchedule = application && [
    'ready_to_request_availability',
    'availability_requested',
    'scheduling_link_sent',
    'ready_to_schedule',
    'scheduled',
    'cancelled',
  ].includes(application.scheduling_status);
  const hasEmails = Boolean(application?.emails?.length);

  const navbarLinks = useMemo(() => {
    if (applicationQuery.isLoading || applicationQuery.isError || !application) {
      return [];
    }

    const links = [{
      label: 'Details',
      location: correctPath(`/app/candidates/${application.id}/details`),
    }];

    if (hasEmails) {
      links.unshift({
        label: 'Emails',
        location: correctPath(`/app/candidates/${application.id}/emails`),
      });
    }

    if (hasAvailabilities) {
      links.unshift({
        label: 'Availabilities',
        location: correctPath(`/app/candidates/${application.id}/availabilities`),
      });
    }

    if (hasHiringMeetings) {
      links.unshift({
        label: 'Hiring Meetings',
        location: correctPath(`/app/candidates/${application.id}/hiring-meetings`),
      });
    }

    if (hasSchedules || hasSelfSchedulingLinks) {
      links.unshift({
        label: 'Schedules',
        location: correctPath(`/app/candidates/${application.id}/schedules`),
      });
    }

    return links;
  }, [application, hasAvailabilities, hasHiringMeetings, hasSchedules, hasSelfSchedulingLinks, hasEmails]);

  // Handle ATS ID redirect
  const fetchATSApplications = Boolean(idType === 'ats' && account?.ats_type && atsUsingApplications.includes(account.ats_type));
  const fetchATSCandidates = Boolean(idType === 'ats' && account?.ats_type && atsUsingCandidates.includes(account.ats_type));
  let additionalPath = pathname.replace(correctPath(`/app/candidates/${id}`), '');

  const {
    data: atsApplications,
    isLoading: isATSApplicationsLoading,
    refetch: refetchApplications,
  } = useApplications({ ats_id: id }, {
    enabled: fetchATSApplications,
  });
  const {
    data: atsCandidates,
    isLoading: isATSCandidatesLoading,
    refetch: refetchCandidates,
  } = useCandidates({ ats_id: id }, {
    enabled: fetchATSCandidates,
  });

  const fetchedApplication = fetchATSApplications && atsApplications?.applications && atsApplications.applications.length > 0 ?
    atsApplications.applications[0] :
    fetchATSCandidates && atsCandidates?.candidates && atsCandidates.candidates.length > 0 && atsCandidates.candidates[0].applications?.length > 0 ?
      atsCandidates.candidates[0].applications[0] :
      undefined;

  useEffect(() => {
    // eslint-disable-next-line no-undef
    let interval: NodeJS.Timeout;
    if (idType === 'ats') {
      interval = setInterval(() => {
        if (!fetchedApplication) {
          refetchApplications();
          refetchCandidates();
        }
      }, 1000);

      if (fetchedApplication) {
        clearInterval(interval);
      }
    }
    return () => clearInterval(interval);

  }, [idType, fetchedApplication]);

  const {
    data: stageInterviews,
    isLoading: areStageInterviewsLoading,
  } = useStageInterviews({ job: fetchedApplication?.job_id }, {
    enabled: Boolean(interviewId) && Boolean(fetchedApplication),
  });

  if (idType === 'ats' && (isATSApplicationsLoading || isATSCandidatesLoading || areStageInterviewsLoading)) {
    return <LoadingSpinner />;
  }

  if (fetchedApplication) {
    if (availabilityId) {
      // If we're on the availability page, there's a chance that the availability ID in the URL is an ATS ID as well,
      // so we go through all the availabilities to make see if we can find a match, and if we can, get the real ID for
      // it and replace it in the URL that we're going to redirect to.
      const newAvailabilityId = fetchedApplication.all_availabilities?.find((availability) => availability.ats_id === availabilityId)?.id;
      if (newAvailabilityId) {
        additionalPath = additionalPath.replace(availabilityId, newAvailabilityId);
      }
    }

    if (scheduleId) {
      // If we're on the schedule page, there's a chance that the schedule ID in the URL is an ATS ID as well, so we go
      // through all the schedules to make see if we can find a match, and if we can, get the real ID for it and
      // replace it in the URL that we're going to redirect to.
      const schedules = [...(fetchedApplication.all_schedules || []), ...(fetchedApplication.held_schedules || [])];
      let newScheduleId = schedules.find((schedule) => schedule.ats_id === scheduleId)?.id;

      if (!newScheduleId) {
        // We didn't find a schedule where the ID is its ATS ID, but there's a chance that this ID is the ATS ID of the
        // interview, so we now go through and look at those. This is because several ATSs don't have a concept of a
        // "schedule", so they don't have an ID for that schedule.
        newScheduleId = schedules.find((schedule) => schedule.interviews?.some((interview) => interview.ats_id === scheduleId))?.id;
      }

      if (newScheduleId) {
        additionalPath = additionalPath.replace(scheduleId, newScheduleId);
      }
    }

    if (interviewId && stageInterviews?.stage_interviews) {
      // If we're on the manual schedule page, the interview ID in the URL is an ATS ID as well, so we go
      // replace it in the URL with the stage and interview we're going to redirect to.
      const newStageInterview = stageInterviews.stage_interviews.find(({ ats_id }) => ats_id === interviewId);
      if (newStageInterview) {
        additionalPath = `${additionalPath}?stage=${newStageInterview.stage_id}&interview=${newStageInterview.id}`;
      }
    }

    return <Redirect to={correctPath(`/app/candidates/${fetchedApplication.id}${additionalPath}${hash}`)} />;
  }

  if (idType === 'ats') {
    return (
      <Breadcrumb
        data={{
          title: id,
          pathname,
        }}
      >
        <CandidateSyncDelayMessage />
      </Breadcrumb>
    );
  }

  if (applicationQuery.isLoading) {
    return <LoadingSpinner />;
  }

  const isSuccess = clickedCancelScheduleButton || clickedCancelSelfSchedulingLinkButton || clickedCancelAvailabilityButton;

  return (
    <Breadcrumb
      data={{
        title: applicationQuery.isError ? id : `${application?.candidate.name || 'Unknown'} (${application?.job.name})`,
        pathname: correctPath(`/app/candidates/${id}`),
      }}
    >
      {applicationQuery.isError ?
        <Flash
          message={applicationQuery.error.message}
          showFlash={applicationQuery.isError}
          type="danger"
        /> :
        <div className="candidate-container">
          {application?.active_assignments?.[0] && (
            <CandidateAssignmentBanner
              assignment={application.active_assignments[0]}
            />
          )}
          <Flash
            isDismissible
            message={updateApplicationMutation.error?.message}
            onDismiss={updateApplicationMutation.reset}
            showFlash={updateApplicationMutation.isError}
            type="danger"
          />
          <Flash
            isDismissible
            message={`Candidate ${application?.archived ? 'archived' : 'unarchived'}.`}
            onDismiss={updateApplicationMutation.reset}
            showFlash={updateApplicationMutation.isSuccess}
            type="success"
          />
          <Flash
            isDismissible
            message={clickedCancelAvailabilityButton ? 'Availability request cancelled.' : 'Schedule cancelled.'}
            onDismiss={handleDismissSuccess}
            showFlash={isSuccess}
            type="success"
          />
          <Flash
            isDismissible
            message={actionButtonSuccessFlashMessage}
            onDismiss={() => setActionButtonSuccessFlashMessage('')}
            showFlash={Boolean(actionButtonSuccessFlashMessage)}
            type="success"
          />
          <Flash
            isDismissible
            message={actionButtonErrorFlashMessage}
            onDismiss={() => setActionButtonErrorFlashMessage('')}
            showFlash={Boolean(actionButtonErrorFlashMessage)}
            type="danger"
          />
          {application?.id &&
            <>
              {!((canSchedule && isInSchedulingFlow) || isInHiringMeetingFlow || isInRequestAvailabilityFlow || isInSubmitAvailabilityFlow || isInRequestSelfSchedulingFlow || isInManualSchedulingFlow) &&
                <PageHeader
                  details={
                    <>
                      <div className="details-row">
                        <SchedulingStatusIndicator schedulingStatus={application.scheduling_status} />
                        {!isSmallScreen && Moment(application.candidate.lever_snoozed_until).isAfter(Moment()) && <span className="separator">|</span>}
                        {!isSmallScreen && Moment(application.candidate.lever_snoozed_until).isAfter(Moment()) && <span>Snoozed until {formatMoment(Moment(application.candidate.lever_snoozed_until).tz(Moment.tz.guess()), TimeFormat.ShortMonthDayYearWithTimeAndTimezone)}</span>}
                        {!isSmallScreen && application.scheduling_status === 'inactive' && <span className="separator">|</span>}
                        {!isSmallScreen && application.scheduling_status === 'inactive' && <span>{capitalize(application.status)}</span>}
                        {!isSmallScreen && application.current_stage && <span className="separator">|</span>}
                        {!isSmallScreen && application.current_stage && <span><Link to={correctPath(`/app/jobs/${application.job_id}/stages/${application.current_stage_id}`)}>{application.current_stage.name}</Link></span>}
                        {!isSmallScreen && application.scheduling_status === 'scheduled' && <span className="separator">|</span>}
                        {!isSmallScreen && application.scheduling_status === 'scheduled' && application.active_schedules && <span><Link to={correctPath(`/app/candidates/${id}/schedules`)}>Interview on {formatMoment(Moment(application.active_schedules[0].interviews[0].start_time), TimeFormat.LongMonthDayYear)}</Link></span>}
                      </div>
                      {formatPronouns(application.candidate.pronouns) && (
                        <div className="details-row details-row-pronouns">
                          <FontAwesomeIcon icon={faUser} />
                          <span>{formatPronouns(application.candidate.pronouns)}</span>
                        </div>
                      )}
                    </>
                  }
                  favorite={application.favorite}
                  navbarLinks={navbarLinks}
                  resourceId={application.id}
                  resourceType={FavoriteResourceType.Application}
                  title={(
                    <>
                      {application.candidate.name || (<span className="empty" data-for="name-not-set-tooltip" data-tip>Unknown</span>)}
                      {!application.candidate.name && (
                        <Tooltip id="name-not-set-tooltip" value={`This candidate's name isn't set in ${atsLabels[account?.ats_type!]}. We recommend setting it for the best experience.`} />
                      )}
                      {application.candidate.pronunciation_url && (
                        <PlayAudioButton
                          id={`candidate-${id}-page-header-pronunciation`}
                          src={application.candidate.pronunciation_url}
                          tooltipText="Pronounce name"
                        />
                      )}
                      {' '}
                      <span className="job-name">({application.job.name})</span>
                    </>
                  )}
                  titleIcon={(
                    <>
                      {(application.archived || application.candidate.archived) && <Label color="gray">Archived</Label>}
                      {Moment(application.candidate.lever_snoozed_until).isAfter(Moment()) && (
                        <Label
                          color="gray"
                          tooltip={
                            <Tooltip
                              id="snoozed-tooltip"
                              value={`Snoozed until ${formatMoment(Moment(application.candidate.lever_snoozed_until).tz(Moment.tz.guess()), TimeFormat.ShortMonthDayYearWithTimeAndTimezone)}`}
                            />
                          }
                        >
                          Snoozed
                        </Label>
                      )}
                    </>
                  )}
                >
                  {applicationAssignees && (
                    <>
                      <AssignCandidateModal
                        assignment={application?.active_assignments?.[0]}
                        isOpen={isAssignModalOpen}
                        onToggle={() => setIsAssignModalOpen(!isAssignModalOpen)}
                      />
                      <Button
                        color="gem-outline"
                        iconLeft={<FontAwesomeIcon icon={application?.active_assignments?.[0] ? faShuffle : faUserPlus} />}
                        onClick={() => setIsAssignModalOpen(true)}
                        value={application?.active_assignments?.[0] ? 'Reassign' : 'Assign'}
                      />
                    </>
                  )}
                  <CandidateActionButton
                    application={application}
                    setErrorFlashMessage={setActionButtonErrorFlashMessage}
                    setSuccessFlashMessage={setActionButtonSuccessFlashMessage}
                    size="small"
                  />
                  <OpenInChatButton
                    chatIds={{
                      hiringChannelChatId: application.hiring_channel_id || undefined,
                    }}
                    tooltipValue="Open hiring channel"
                    type="hiring_channel"
                  />
                  <OpenInATSButton
                    atsIds={{
                      applicationAtsId: application.ats_id,
                      candidateAtsId: application.candidate.ats_id,
                    }}
                    type="candidate"
                  />
                  {(application.archived || (!hasActiveSchedules && application.scheduling_status !== 'availability_requested' && application.scheduling_status !== 'scheduling_link_sent')) && !application.candidate.archived && (
                    <ArchiveButton
                      id={id}
                      isArchived={application.archived}
                      onArchive={handleArchive}
                      onUnarchive={handleUnarchive}
                    />
                  )}
                  {application.scheduling_status === 'availability_requested' && (
                    <CancelAvailabilityButton
                      application={application}
                      onSuccess={() => handleCancelAvailabilitySuccess()}
                    />
                  )}
                  {hasActiveSchedules && (
                    <CancelMeetingButton
                      application={application}
                      onSuccess={(scheduleIds) => handleCancelScheduleSuccess(scheduleIds)}
                    />
                  )}
                  {hasRequestedSelfSchedulingLinks && (
                    <CancelSelfSchedulingLinkButton
                      application={application}
                      onSuccess={handleCancelSelfSchedulingLinkSuccess}
                    />
                  )}
                </PageHeader>
              }
              <Switch>
                {/*The redirect to the emails tab doesn't always work because
                the applications list endpoint doesn't include the emails array,
                so if the retrieve response hasn't been received yet, it might
                not know if there are actually any emails. The obvious solution
                is to add emails to the list endpoint, but that endpoint is slow
                enough. This doesn't seem like a big enough deal to fix.*/}
                <Redirect exact from={correctPath('/app/candidates/:id')} to={correctPath(`/app/candidates/:id/${hasSchedules || hasSelfSchedulingLinks ? 'schedules' : (hasAvailabilities ? 'availabilities' : (hasEmails ? 'emails' : 'details'))}`)} />
                {hasAvailabilities && <Route component={CandidateAvailabilities} path={correctPath('/app/candidates/:id/availabilities')} />}
                {hasHiringMeetings && <Route component={CandidateHiringMeetings} path={correctPath('/app/candidates/:id/hiring-meetings')} />}
                {(hasSchedules || hasSelfSchedulingLinks) && <Route component={CandidateSchedules} path={correctPath('/app/candidates/:id/schedules')} />}
                {hasEmails && <Route component={CandidateEmails} path={correctPath('/app/candidates/:id/emails')} />}
                <Route component={CandidateDetails} path={correctPath('/app/candidates/:id/details')} />
                <Route component={CandidateRequestAvailability} path={correctPath('/app/candidates/:id/request-availability')} />
                <Route component={CandidateRequestSelfScheduling} path={correctPath('/app/candidates/:id/self-schedule')} />
                <Route component={CandidateScheduleCreate} path={correctPath('/app/candidates/:id/schedule')} />
                {manualSchedulingFlow && <Route component={CandidateManualSchedule} path={correctPath('/app/candidates/:id/manual-schedule')} />}
                <Route component={CandidateHiringMeetingCreate} path={correctPath('/app/candidates/:id/schedule-hiring-meeting')} />
                <Route component={CandidateSubmitAvailability} path={correctPath('/app/candidates/:id/submit-availability')} />
              </Switch>
            </>
          }
        </div>
      }
    </Breadcrumb>
  );
};

export default Candidate;
