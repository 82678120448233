import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useHistory } from 'react-router-dom';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { filter, includes } from 'lodash';
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import Button from '../library/inputs/Button';
import GemAcquisitionBanner from './GemAcquisitionBanner';
import RequestADemoButton from './RequestADemoButton';
import NavbarLinks from '../library/navigation/NavbarLinks';
import { useSession } from '../../hooks/use-session';
import { correctPath, fullGemPath } from '../../libraries/gem';

const Navbar = () => {
  const history = useHistory();

  const { currentUser, isSessionLoading } = useSession();

  const isMobileNav = useMediaQuery({ query: '(max-width: 930px)' });
  const [isMobileNavExpanded, setIsMobileNavExpanded] = useState(!isMobileNav);

  const toggleNav = () => {
    setIsMobileNavExpanded(!isMobileNavExpanded);
  };

  useEffect(() => {
    !isMobileNav && setIsMobileNavExpanded(false);
  });

  useEffect(() => history.listen(() => {
    setIsMobileNavExpanded(false);
  }));

  let links = [{
    label: 'Home',
    location: '/',
  }, {
    label: 'Features',
    location: '/#features',
  }, {
    label: 'About',
    location: '/about',
  }];

  if (!isMobileNav) {
    links = filter(links, (link) => includes(['Features', 'About'], link.label));
  }

  return (
    <div className={`navbar-container ${isMobileNavExpanded ? 'expanded' : ''}`}>
      <GemAcquisitionBanner />
      <div className="navbar">
        <div className="links">
          <Link className="logo" to="/">
            <img
              alt="InterviewPlanner logo"
              className="logo"
              src="/scheduling/src/assets/images/logo.svg"
            />
          </Link>
          {(!isMobileNav || isMobileNavExpanded) && <NavbarLinks links={links} showActiveStyle={false} />}
          {isMobileNav && isMobileNavExpanded && !isSessionLoading && (
            currentUser ?
              <div className="authenticated-user-return">
                <Button
                  color="orange"
                  size="large"
                  value="Go to app"
                />
                <div className="authenticated-user-message">
                  You are signed in as <b>{currentUser.name}</b>.
                </div>
              </div> :
              <div className="cta-links">
                <a href={fullGemPath('/signin')}>
                  <Button
                    color="blue-outline"
                    size="large"
                    value="Sign in"
                  />
                </a>
                <RequestADemoButton label="Navbar" />
              </div>
          )}
        </div>
        <div className="links buttons">
          {isMobileNav &&
            <Button
              color="gray"
              iconRight={<FontAwesomeIcon icon={isMobileNavExpanded ? faTimes : faBars} />}
              onClick={toggleNav}
            />
          }
          {!isSessionLoading && !isMobileNav &&
            (currentUser ?
              <div className="authenticated-user-return">
                <div className="authenticated-user-message">
                  You are signed in as <b>{currentUser.name}</b>.
                </div>
                <Link to={correctPath('/app')}>
                  <Button
                    color="orange"
                    size="large"
                    value="Go to app"
                  />
                </Link>
              </div> :
              <div className="cta-links">
                <a href={fullGemPath('/signin')}>
                  <Button
                    color="blue-outline"
                    size="large"
                    value="Sign in"
                  />
                </a>
                <RequestADemoButton label="Navbar" />
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
};

export default Navbar;
