import { HelmetProvider } from 'react-helmet-async';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { createRoot } from 'react-dom/client';

import 'styles/application/gem/index.scss';

import './libraries/fullstory';
import './libraries/sentry';
import App from './components/App';
import LaunchDarklyProvider from './components/library/utils/LaunchDarklyProvider';
import { LocalStorageProvider } from './hooks/use-local-storage';
import { SessionProvider } from './hooks/use-session';
import { logPageView } from './libraries/analytics';
import { queryClient } from './libraries/query-client';

import type { ReactElement, Ref, RefAttributes } from 'react';

const history = createBrowserHistory();
history.listen(() => logPageView());
logPageView();

const container = document.getElementById('root');
const root = createRoot(container!);

// In development, we were having trouble serving the webpack dev server through the gem development proxy because of
// HMR, websockets, and paths. The solution was to set the publicPath property in the webpack.config.js to include the
// subdirectory that we're access it from (i.e. /scheduling/), but that pretty much breaks the dev server. So this is a
// way to do it dynamically, and while it is a bit hacky, it's only for development, and it makes the page reload when
// you change a file, which is the most important part.
if (process.env.NODE_ENV === 'development') {
  // @ts-ignore
  __webpack_public_path__ = '/scheduling/';
}

root.render(
  <Router history={history}>
    <HelmetProvider>
      <LocalStorageProvider>
        <QueryClientProvider client={queryClient}>
          <SessionProvider>
            <LaunchDarklyProvider>
              <App />
            </LaunchDarklyProvider>
            {process.env.NODE_ENV === 'development' && <ReactQueryDevtools initialIsOpen={false} />}
          </SessionProvider>
        </QueryClientProvider>
      </LocalStorageProvider>
    </HelmetProvider>
  </Router>
);

/**
 * This reopens react and modifies forwardRef to allow generics. This was
 * inspired by this post:
 * https://fettblog.eu/typescript-react-generic-forward-refs/#option-3%3A-augment-forwardref
 *
 * It would be better to isolate this into its own file, but I was not able to
 * get that working. Everytime I tried, it would "overwrite" the react module
 * instead of modify it, so it would make it seem like forwardRef was the only
 * thing in the react package.
 *
 * Another downside to this is that we lose the ability to add a displayName to
 * the returned component, which would make things easier when debugging.
 * Whenever I try adding displayName as an option property on the return type of
 * forwardRef, it makes the generics stop working. I don't know enough about
 * Typescript to debug why that's happening.
 */
declare module 'react' {
  function forwardRef<T, P = {}> (
      render: (props: P, ref: Ref<T>) => ReactElement | null
  ): (props: P & RefAttributes<T>) => ReactElement | null;
}
