import { StyledButton } from './styles';

import type { MouseEventHandler, ReactNode } from 'react';

const MICROSOFT_LOGO = '/scheduling/src/assets/images/microsoft-logo.svg';

interface Props {
  isDisabled: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
  value: ReactNode;
}

const MicrosoftButton = ({ isDisabled, onClick, value }: Props) => (
  <StyledButton
    className="btn-microsoft"
    color="no-outline"
    iconLeft={<img alt="Microsoft" src={MICROSOFT_LOGO} />}
    isDisabled={isDisabled}
    onClick={onClick}
    value={value}
  />
);

export default MicrosoftButton;
