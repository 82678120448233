import { useCallback, useState } from 'react';
import { Breadcrumb } from 'react-breadcrumbs';
import { useHistory } from 'react-router-dom';

import Button from 'components/library/inputs/Button';
import JobSelectInput from 'components/library/inputs/JobSelectInput';
import TextInput from 'components/library/inputs/TextInput';
import Section from 'components/library/layout/Section';
import Flash from 'components/library/utils/Flash';
import { useCreateInterviewBatch } from 'hooks/queries/interview-batches';
import { correctPath } from 'libraries/gem';
import CreateTimesTable from './CreateTimesTable';
import { StyledForm, StyledFormRowContainer, StyledSubmitButtonContainer } from './styles';

import type { FormEvent } from 'react';
import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option } from 'components/library/inputs/SelectInput/types';
import type { EditableInterviewBatchTime } from 'types';

const InterviewBatchCreate = () => {
  const history = useHistory();

  const [name, setName] = useState('');
  const [jobId, setJobId] = useState('');
  const [times, setTimes] = useState<EditableInterviewBatchTime[]>([]);

  const createInterviewBatchMutation = useCreateInterviewBatch();

  const handleSubmit = useCallback(async (e: FormEvent) => {
    e.preventDefault();
    createInterviewBatchMutation.reset();

    try {
      const data = await createInterviewBatchMutation.mutateAsync({
        payload: {
          name,
          job_id: jobId,
          times,
        },
      });
      history.push(correctPath(`/app/interview-batches/${data.id}`));
    } catch (_) {
      // Since React Query catches the error and attaches it to the mutation, we
      // don't need to do anything with this error besides prevent it from
      // bubbling up.
    }
  }, [createInterviewBatchMutation, history]);

  return (
    <Breadcrumb
      data={{
        title: 'Create',
        pathname: correctPath('/app/interview-batches/create'),
      }}
    >
      <Section title="Create a new interview batch">
        <Flash
          message={createInterviewBatchMutation.error?.message}
          showFlash={createInterviewBatchMutation.isError}
          type="danger"
        />
        <StyledForm onSubmit={handleSubmit}>
          <StyledFormRowContainer>
            <TextInput
              isRequired
              label="Batch Name"
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
            <JobSelectInput
              label="Job"
              onChange={(option: OnChangeValue<Option<string>, false>) => setJobId(option?.value || '')}
              value={jobId}
            />
          </StyledFormRowContainer>
          <CreateTimesTable
            jobId={jobId}
            setTimes={setTimes}
            times={times}
          />
          <StyledSubmitButtonContainer>
            <Button
              color="gem-blue"
              size="large"
              type="submit"
              value="Create Interview Batch"
            />
          </StyledSubmitButtonContainer>
        </StyledForm>
      </Section>
    </Breadcrumb>
  );
};

export default InterviewBatchCreate;
