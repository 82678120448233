import Moment from 'moment-timezone';
import { Breadcrumb } from 'react-breadcrumbs';
import { Link, Redirect, Route, Switch, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import EmailSettingsWarningModal from '../EmailSettingsWarningModal';
import EmailStep from './EmailStep';
import { EmailTemplateType } from 'types';
import Flash from '../../../../library/utils/Flash';
import LoadingSpinner from '../../../../library/utils/LoadingSpinner';
import PreferencesStep from './PreferencesStep';
import ReviewStep from './ReviewStep';
import Section from '../../../../library/layout/Section';
import SendLinkStep from './SendLinkStep';
import StepProgressBar from '../../../../library/navigation/StepProgressBar';
import SuggestedTimesStep from './SuggestedTimesStep';
import { NewAvailabilityProvider, Step, useNewAvailability } from './use-new-availability';
import { useApplication } from '../../../../../hooks/queries/applications';
import { useSession } from 'hooks/use-session';

import type { ReactNode } from 'react';
import { correctPath } from 'libraries/gem';

// To enable the inner component to access the context value, it needs to be nested
// under the provider, so we need this wrapper component to add that nesting.
const CandidateRequestAvailability = () => {
  return (
    <NewAvailabilityProvider>
      <CandidateRequestAvailabilityInner />
    </NewAvailabilityProvider>
  );
};

export default CandidateRequestAvailability;

const CandidateRequestAvailabilityInner = () => {
  const { id } = useParams<{ id: string }>();

  const { account, currentUser } = useSession();

  const {
    data: application,
    isLoading,
  } = useApplication(id);

  const {
    availability,
    completedStep,
    setAvailability,
  } = useNewAvailability();

  useEffect(() => {
    if (!isLoading && !availability.application_id && application?.current_stage?.schedule_template && application.current_stage.availability_template) {
      setAvailability({
        application_id: application.id,
        manual: false,
        availability_template: {
          enforce: false,
          rolling_window_days: application.current_stage.availability_template.rolling_window_days || 14,
          business_hours: (application.current_stage.schedule_template.business_hours || []).map((businessHour) => ({
            day: businessHour.day,
            start_time: businessHour.start_time,
            end_time: businessHour.end_time,
            timezone: businessHour.timezone || Moment.tz.guess(),
          })),
          advanced_notice_hours: application.current_stage.availability_template.advanced_notice_hours,
          suggest_times: application.current_stage.availability_template.suggest_times,
          minimum_duration_minutes: application.current_stage.availability_template.minimum_duration_minutes,
          total_duration_minutes: application.current_stage.availability_template.total_duration_minutes,
          availability_request_email_template: application.current_stage.availability_template.availability_request_email_template && {
            id: application.current_stage.availability_template.availability_request_email_template.id,
            name: application.current_stage.availability_template.availability_request_email_template.name,
            subject: application.current_stage.availability_template.availability_request_email_template.subject,
            sender_name: application.current_stage.availability_template.availability_request_email_template.sender_name,
            sender_email: application.current_stage.availability_template.availability_request_email_template.sender_email,
            cc_emails: application.current_stage.availability_template.availability_request_email_template.cc_emails,
            bcc_emails: application.current_stage.availability_template.availability_request_email_template.bcc_emails,
            body: application.current_stage.availability_template.availability_request_email_template.body,
            attachments: application.current_stage.availability_template.availability_request_email_template.attachments,
          },
        },
      });
    }
  }, [Boolean(!isLoading && !availability.application_id)]);

  const hasExistingAvailabilityRequest = Boolean(application?.active_availabilities?.filter(({ ats_id, manual }) => !manual && !Boolean(ats_id))?.length);
  const isInWorkflow = completedStep > Step.Preferences;

  const canRequestAvailability = application?.current_stage?.availability_template && ((!hasExistingAvailabilityRequest && [
    'ready_to_request_availability',
    'ready_to_schedule',
    'scheduled',
    'cancelled',
  ].includes(application.scheduling_status)) || isInWorkflow);

  const canSendEmail = Boolean(account?.email_domain || currentUser?.gem_can_send_email);
  const [isEmailSettingsWarningModalOpen, setIsEmailSettingsWarningModalOpen] = useState(!canSendEmail);

  if (isLoading || (canRequestAvailability && !availability.application_id)) {
    return <LoadingSpinner />;
  }

  const errorMessages: Record<string, ReactNode> = {
    unrequestable: (
      <span>
        You have not enabled requesting availability for candidates in the {application?.current_stage?.name} stage.&nbsp;
        <Link to={correctPath(`/app/jobs/${application?.job_id}/stages/${application?.current_stage_id}`)}>
          Enable requesting availability here.
        </Link>
      </span>
    ),
    availability_requested: (
      <span>
        You have already requested availability from this candidate for {application?.current_stage?.name}.&nbsp;
        <Link to={correctPath(`/app/candidates/${application?.id}/availabilities`)}>
          View the availability request here.
        </Link>
      </span>
    ),
    inactive: 'You cannot request availability from an inactive candidate.',
  };

  return (
    <Breadcrumb
      data={{
        title: 'Request Availability',
        pathname: correctPath(`/app/candidates/${application?.id}/request-availability`),
      }}
    >
      {canRequestAvailability ?
        <Section
          className="candidate-request-availability-container"
          title={`Request availability from ${application?.candidate.name} for ${application?.current_stage?.name}`}
        >
          <EmailSettingsWarningModal
            isOpen={isEmailSettingsWarningModalOpen}
            onToggle={() => setIsEmailSettingsWarningModalOpen(false)}
            type={EmailTemplateType.AvailabilityRequestEmail}
          />
          <StepProgressBar
            completedStep={completedStep}
            // We don't want them to be able to click back after it's already
            // been created.
            isDisabled={completedStep === Step.SendLink}
            steps={[{
              label: 'Preferences',
              location: correctPath(`/app/candidates/${application.id}/request-availability/preferences`),
            }, availability.availability_template?.suggest_times && completedStep >= Step.Preferences && {
              label: 'Suggested Times',
              location: correctPath(`/app/candidates/${application.id}/request-availability/suggested-times`),
            }, {
              label: 'Email',
              location: correctPath(`/app/candidates/${application.id}/request-availability/email`),
            }, {
              label: 'Review',
              location: correctPath(`/app/candidates/${application.id}/request-availability/review`),
            }, !availability.availability_template?.availability_request_email_template && completedStep >= Step.Email && {
              label: 'Send Link',
              location: correctPath(`/app/candidates/${application.id}/request-availability/send`),
            }].filter((step): step is { label: string; location: string } => Boolean(step))}
          />
          <div className="candidate-request-availability-steps-container">
            <Switch>
              <Redirect exact from={correctPath('/app/candidates/:id/request-availability')} to={correctPath('/app/candidates/:id/request-availability/preferences')} />
              <Route component={PreferencesStep} path={correctPath('/app/candidates/:id/request-availability/preferences')} />
              <Route component={SuggestedTimesStep} path={correctPath('/app/candidates/:id/request-availability/suggested-times')} />
              <Route component={EmailStep} path={correctPath('/app/candidates/:id/request-availability/email')} />
              <Route component={ReviewStep} path={correctPath('/app/candidates/:id/request-availability/review')} />
              <Route component={SendLinkStep} path={correctPath('/app/candidates/:id/request-availability/send')} />
            </Switch>
          </div>
        </Section> :
        <Flash
          message={errorMessages[(
            application?.current_stage?.availability_template_id ? (
              hasExistingAvailabilityRequest ?
                'availability_requested' :
                application.scheduling_status
            ) :
              'unrequestable'
          )]}
          showFlash
          type="danger"
        />
      }
    </Breadcrumb>
  );
};
