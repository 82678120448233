import { pick } from 'lodash';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import EmailPreview from './EmailPreview';
import EmailTemplateForm from '../inputs/EmailTemplateForm';
import ErrorTokenFlash from '../utils/ErrorTokenFlash';
import Flash from '../utils/Flash';
import LoadingSpinner from '../utils/LoadingSpinner';
import Modal from '../layout/Modal';
import { EditorType } from '../../../types';
import { EmailTemplateType, ScheduleStatus } from '../../../types';
import { NEW_EMAIL_TEMPLATE } from '../../Application/EmailTemplates/EmailTemplateCreate/helpers';
import { ResolveField, resolveHiringRole } from '../../../libraries/email';
import { htmlToSlateValue } from '../../../libraries/editor/html-to-slate-value';
import { slateValueToHtml } from '../../../libraries/editor/slate-value-to-html';
import { slateValueToText } from '../../../libraries/editor/slate-value-to-text';
import { textToSlateValue } from '../../../libraries/editor/text-to-slate-value';
import { translateSlateValueBetweenEditorTypes } from '../../../libraries/editor/translate-slate-value-between-editor-types';
import { useApplication } from '../../../hooks/queries/applications';
import { useCreateEmail } from '../../../hooks/queries/emails';
import { useRender, useTokens } from '../../../hooks/queries/tokens';
import { useSession } from '../../../hooks/use-session';
import { useUsersMap } from '../../../hooks/queries/users';

import type { CreatableEmailTemplate, Token } from '../../../types';
import type { CreateEmailPayload } from '../../../hooks/queries/emails';
import { correctPath } from 'libraries/gem';

interface Props {
  applicationId: string;
  emailTemplateType?: `${EmailTemplateType}`;
  isOpen: boolean;
  onToggle: () => void;
}

const EmailCandidateModal = ({ applicationId, emailTemplateType = EmailTemplateType.ManualEmail, isOpen, onToggle }: Props) => {
  const history = useHistory();

  const { currentUser } = useSession();
  const users = useUsersMap({ archived: true });
  const { data: application } = useApplication(applicationId, { enabled: isOpen });

  const [isEditing, setIsEditing] = useState(true);
  const [emailTemplate, setEmailTemplate] = useState<CreatableEmailTemplate | undefined>(NEW_EMAIL_TEMPLATE);
  const [errorTokens, setErrorTokens] = useState<Token[]>([]);

  useEffect(() => {
    if (emailTemplate) {
      const confirmedSchedules = (application?.active_schedules || []).filter(({ status }) => status === ScheduleStatus.Confirmed);
      const lastConfirmedSchedule = confirmedSchedules[confirmedSchedules.length - 1];
      if (emailTemplate.type === EmailTemplateType.ConfirmationEmail && Boolean(lastConfirmedSchedule?.block_id)) {
        setEmailTemplate((prevTemplate) => (prevTemplate ? {
          ...prevTemplate,
          subject: slateValueToText(
            translateSlateValueBetweenEditorTypes(
              textToSlateValue(emailTemplate.subject || ''),
              EditorType.ConfirmationEmail,
              EditorType.MultiBlockConfirmationEmail,
            )
          ),
          body: slateValueToHtml(
            translateSlateValueBetweenEditorTypes(
              htmlToSlateValue(emailTemplate.body || ''),
              EditorType.ConfirmationEmail,
              EditorType.MultiBlockConfirmationEmail,
            )
          ),
        } : undefined));
      }
    }
  }, [emailTemplate?.id]);

  const { data: tokens } = useTokens({
    type: emailTemplateType,
    application_id: applicationId,
  }, { enabled: isOpen });

  const {
    data: renderedSubject,
    error: subjectRenderError,
  } = useRender({
    type: emailTemplateType,
    plain_text: true,
    application_id: applicationId,
    text: emailTemplate?.subject,
  }, { enabled: !isEditing });

  const {
    data: renderedBody,
    error: bodyRenderError,
  } = useRender({
    type: emailTemplateType,
    application_id: applicationId,
    text: emailTemplate?.body,
  }, { enabled: !isEditing });

  const createEmailMutation = useCreateEmail();

  const toggleIsEditing = () => {
    setIsEditing((prev) => !prev);
  };

  const handleNext = () => {
    toggleIsEditing();
  };

  const handleSubmit = async () => {
    if (!emailTemplate) {
      return;
    }

    const payload: CreateEmailPayload = {
      email_template: pick(emailTemplate, [
        'id',
        'subject',
        'sender_name',
        'sender_email',
        'cc_emails',
        'bcc_emails',
        'attachments',
        'body',
      ]),
    };

    try {
      await createEmailMutation.mutateAsync({ applicationId, payload });
      onToggle();
      // Reset the state in case they open it again.
      setIsEditing(true);
      setEmailTemplate(NEW_EMAIL_TEMPLATE);
      history.push(correctPath(`/app/candidates/${applicationId}/emails`));
    } catch (_) {
      // Since React Query catches the error and attaches it to the mutation, we
      // don't need to do anything with this error besides prevent it from
      // bubbling up.
    }
  };

  return (
    <Modal
      cancelButtonValue={isEditing ? 'Cancel' : 'Back'}
      className="email-candidate-modal"
      isOpen={isOpen}
      isSubmitting={createEmailMutation.isLoading}
      onCancel={isEditing ? undefined : toggleIsEditing}
      onSubmit={isEditing ? handleNext : handleSubmit}
      onToggle={onToggle}
      submitButtonIsDisabled={errorTokens.length > 0}
      submitButtonValue={isEditing ? 'Review' : 'Send Email'}
      title="Email candidate"
    >
      {!currentUser || !emailTemplate || !application ?
        <LoadingSpinner /> :
        <>
          <Flash
            message={createEmailMutation.error?.message}
            showFlash={createEmailMutation.isError}
            type="danger"
          />
          <ErrorTokenFlash errorTokens={errorTokens} />
          {isEditing ?
            <EmailTemplateForm
              emailTemplate={emailTemplate}
              pendingPreviewMessage="This token will be filled in when sending an email to a candidate."
              setEmailTemplate={setEmailTemplate}
              setErrorTokens={setErrorTokens}
              tokens={tokens}
              type={emailTemplateType}
            /> :
            <>
              <Flash
                message={subjectRenderError?.message}
                showFlash={Boolean(subjectRenderError)}
                type="danger"
              />
              <Flash
                message={bodyRenderError?.message}
                showFlash={Boolean(bodyRenderError)}
                type="danger"
              />
              <EmailPreview
                attachments={emailTemplate.attachments}
                bccEmails={(emailTemplate.bcc_emails || []).map((email) => resolveHiringRole(email, ResolveField.Email, currentUser, application.job, application, users))}
                body={renderedBody?.rendered_text || <LoadingSpinner />}
                ccEmails={(emailTemplate.cc_emails || []).map((email) => resolveHiringRole(email, ResolveField.Email, currentUser, application.job, application, users))}
                senderEmail={resolveHiringRole(emailTemplate.sender_email, ResolveField.Email, currentUser, application.job, application, users)}
                senderName={resolveHiringRole(emailTemplate.sender_name, ResolveField.Name, currentUser, application.job, application, users)}
                subject={renderedSubject?.rendered_text || <LoadingSpinner />}
                to={application.candidate.email}
              />
            </>
          }
        </>
      }
    </Modal>
  );
};

export default EmailCandidateModal;
