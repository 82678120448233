import { Link } from 'react-router-dom';
import { find } from 'lodash';
import { useEffect, useMemo } from 'react';

import CalendarEventPreview from 'components/library/data-display/CalendarEventPreview';
import Flash from 'components/library/utils/Flash';
import LoadingSpinner from 'components/library/utils/LoadingSpinner';
import Token from 'components/library/inputs/EditorInput/Token';
import TokenizedString from 'components/library/data-display/TokenizedString';
import { CalendarEventTemplateType, ElementType, Token as TokenType } from 'types';
import { ResolveField, resolveHiringRole } from 'libraries/email';
import { StyledEditorInput } from './styles';
import { useApplication } from 'hooks/queries/applications';
import { useCalendarEventTemplate } from 'hooks/queries/calendar-event-templates';
import { useJob } from 'hooks/queries/jobs';
import { useSession } from 'hooks/use-session';
import { useSlateEditor } from 'hooks/use-slate-editor';
import { useUsersMap } from 'hooks/queries/users';

import type { Guest } from 'components/library/data-display/CalendarEventPreview';
import type { TokensResponse, RSVP, Job, User, Application } from 'types';
import type { UserMap } from 'hooks/queries/users';
import { correctPath } from 'libraries/gem';

const calendarEventTemplateTypesThatIncludeCandidate: `${CalendarEventTemplateType}`[] = [
  CalendarEventTemplateType.CandidateCalendarEvent,
];

function convertAttendeeToGuest (attendee: string, description: string, currentUser: User | undefined, job: Job | undefined, application: Application | undefined, users: UserMap): Guest {
  attendee = resolveHiringRole(attendee, ResolveField.Email, currentUser, job, application, users);
  const internalUser = find(Object.values(users), (user) => user.email === attendee);
  const guest: Guest = {
    name: attendee,
    description,
  };
  if (internalUser) {
    guest.id = internalUser.id;
    guest.name = internalUser.name || internalUser.email;
  }
  return guest;
}

interface Props {
  // Either applicationId or jobId is required.
  applicationId?: string;
  // Either calendarEventTemplate or id is required.
  calendarEventTemplate?: {
    title: string;
    description: string;
    location?: string;
    additional_attendees?: string[];
    additional_optional_attendees?: string[];
    type: `${CalendarEventTemplateType}`;
  };
  candidateName?: string;
  candidateRSVP?: `${RSVP}`;
  hiringMeetingAttendees?: {
    id: string;
    rsvp?: `${RSVP}`;
  }[];
  directoryCalendarId?: string;
  directoryEventId?: string;
  endTime?: Date;
  id?: string;
  isDateDisplayed?: boolean;
  isVideoConferencingEnabled?: boolean;
  jobId?: string;
  pendingPreviewMessage?: string;
  roomId?: string;
  showFlash?: boolean;
  startTime?: Date;
  timezone?: string;
  // If you want the title and description to be rendered as-is, omit the
  // tokens. Putting rendered descriptions in an editor actually causes some
  // issues with the agendas (i.e. the newlines don't show up properly).
  tokens?: TokensResponse;
}

const CalendarEventTemplateSummary = ({
  applicationId,
  calendarEventTemplate: calendarEventTemplateFromProps,
  candidateName,
  candidateRSVP,
  directoryCalendarId,
  directoryEventId,
  endTime,
  id,
  isDateDisplayed = false,
  isVideoConferencingEnabled,
  hiringMeetingAttendees,
  jobId,
  pendingPreviewMessage = '',
  roomId,
  showFlash = false,
  startTime,
  timezone,
  tokens,
}: Props) => {
  const {
    data: calendarEventTemplateFromId,
    isLoading: isCalendarEventTemplateLoading,
  } = useCalendarEventTemplate(id);
  const calendarEventTemplate = calendarEventTemplateFromId || calendarEventTemplateFromProps;

  const {
    data: job,
    isLoading: isJobLoading,
  } = useJob(jobId);

  const {
    data: application,
    isLoading: isApplicationLoading,
  } = useApplication(applicationId);

  const { currentUser } = useSession();
  const users = useUsersMap({ archived: true });

  const [slateEditor, slateValue, , setValue] = useSlateEditor(calendarEventTemplate?.description || '');

  useEffect(() => {
    if (calendarEventTemplate?.description) {
      setValue(calendarEventTemplate?.description);
    }
  }, [calendarEventTemplate?.description]);

  const guests = useMemo<Guest[]>(() => {
    const g: Guest[] = [];

    const includeCandidateAsGuest = Boolean(calendarEventTemplate?.type && calendarEventTemplateTypesThatIncludeCandidate.includes(calendarEventTemplate.type));
    if (includeCandidateAsGuest) {
      g.push({
        description: 'Candidate',
        name: candidateName || <Token
          element={{ token: TokenType.CandidateEmail, type: ElementType.Token, children: [{ text: '' }] }}
          pendingPreviewMessage={pendingPreviewMessage}
          tokens={{
            [TokenType.CandidateEmail]: {
              pending: true,
              disabled: false,
            },
          } as TokensResponse}
          type={calendarEventTemplate?.type || CalendarEventTemplateType.CandidateCalendarEvent}
        />,
        rsvp: candidateRSVP,
      });
    }

    (hiringMeetingAttendees || []).forEach(({ id, rsvp }) => {
      g.push({
        id,
        name: users[id]?.name || id,
        description: 'Attendee',
        rsvp,
      });
    });

    (calendarEventTemplate?.additional_attendees || []).forEach((attendee) => {
      g.push(convertAttendeeToGuest(attendee, 'Attendee', currentUser, job, application, users));
    });

    (calendarEventTemplate?.additional_optional_attendees || []).forEach((attendee) => {
      g.push(convertAttendeeToGuest(attendee, 'Optional Attendee', currentUser, job, application, users));
    });

    return g;
  }, [
    calendarEventTemplate?.additional_attendees,
    calendarEventTemplate?.additional_optional_attendees,
    calendarEventTemplate?.type,
    candidateName,
    candidateRSVP,
    currentUser,
    hiringMeetingAttendees,
    job,
    users,
  ]);

  if (
    !currentUser ||
    !(job || application) ||
    isCalendarEventTemplateLoading ||
    isJobLoading ||
    isApplicationLoading ||
    !calendarEventTemplate
  ) {
    return <LoadingSpinner />;
  }

  return (
    <div className="calendar-event-template-summary">
      <CalendarEventPreview
        description={tokens && calendarEventTemplate.description ? (
          <StyledEditorInput
            editor={slateEditor}
            isDisabled
            pendingPreviewMessage={pendingPreviewMessage}
            showToolbar={false}
            tokens={tokens}
            type={calendarEventTemplate.type}
            value={slateValue}
          />
        ) :
          calendarEventTemplate.description
        }
        directoryCalendarId={directoryCalendarId}
        directoryEventId={directoryEventId}
        endTime={endTime}
        guests={guests}
        isDateDisplayed={isDateDisplayed}
        isVideoConferencingEnabled={isVideoConferencingEnabled}
        // When video conferencing is enabled and an ID was passed in, it means
        // we're just using the template, not a real calendar event. This means
        // we shouldn't use the template's location since that will be
        // overwritten in the future, so we just pass in undefined.
        location={isVideoConferencingEnabled && id ? undefined : calendarEventTemplate.location}
        roomId={roomId}
        startTime={startTime}
        timezone={timezone}
        title={tokens ? (
          <TokenizedString
            pendingPreviewMessage={pendingPreviewMessage}
            tokens={tokens}
            type={calendarEventTemplate.type}
            value={calendarEventTemplate.title}
          />
        ) :
          calendarEventTemplate.title
        }
      />
      <Flash
        message={<span>This template is linked to other stages. You can edit its details from its <Link to={correctPath(`/app/calendar-event-templates/${id}`)}>calendar event template page</Link>.</span>}
        showFlash={showFlash}
        type="info"
      />
    </div>
  );
};

export default CalendarEventTemplateSummary;
