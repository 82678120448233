import { useMemo, useState } from 'react';

import Table from 'components/library/data-display/Table';
import ArchiveButton from 'components/library/inputs/ArchiveButton';
import Flash from 'components/library/utils/Flash';
import { formatDateRange } from 'libraries/formatters';
import { useUpdateInterviewBatch } from 'hooks/queries/interview-batches';
import { StyledTableContainer } from './styles';

import type { ChangeEvent } from 'react';
import type { TableSchema } from 'components/library/data-display/Table';
import type { InterviewBatch } from 'types';
import { correctPath } from 'libraries/gem';

const getRowLink = ({ id }: InterviewBatch) => correctPath(`/app/interview-batches/${id}`);

interface Props {
  interviewBatches: InterviewBatch[];
  onPageNumberChange: (pageNumber: number) => void;
  onShowArchivedChange: (event: ChangeEvent<HTMLInputElement>) => void;
  pageNumber: number;
  showArchived: boolean;
  totalCount: number;
}

const InterviewBatchListTable = ({
  interviewBatches,
  onPageNumberChange,
  onShowArchivedChange,
  pageNumber,
  showArchived,
  totalCount,
}: Props) => {
  const [lastAction, setLastAction] = useState({
    id: '',
    isUndo: false,
    type: '',
  });

  const updateInterviewBatchMutation = useUpdateInterviewBatch();

  const handleArchive = (id: string, undo: boolean) => {
    setLastAction({
      id,
      type: 'archive',
      isUndo: undo,
    });
    updateInterviewBatchMutation.reset();
    updateInterviewBatchMutation.mutate({ id, payload: { archived: true } });
  };

  const handleUnarchive = (id: string, undo: boolean) => {
    setLastAction({
      id,
      type: 'unarchive',
      isUndo: undo,
    });
    updateInterviewBatchMutation.reset();
    updateInterviewBatchMutation.mutate({ id, payload: { archived: false } });
  };

  const handleUndo = () => {
    if (lastAction.type === 'archive') {
      handleUnarchive(lastAction.id, true);
    }
    if (lastAction.type === 'unarchive') {
      handleArchive(lastAction.id, true);
    }
  };

  const columns = useMemo<TableSchema<InterviewBatch>>(() => [{
    header: 'Batch Name',
    displayValue: ({ name }) => name,
  }, {
    header: 'Job',
    displayValue: ({ job }) => job?.name,
  }, {
    header: 'Interview Dates',
    displayValue: ({ earliest_interview_date, latest_interview_date }) => formatDateRange(earliest_interview_date, latest_interview_date),
  }, {
    header: 'Interviews Booked',
    displayValue: ({ linked_interviews }) => `${linked_interviews}`,
  }, {
    header: 'Actions',
    isClickable: true,
    displayValue: ({ id, archived }) => (
      <div className="action-buttons-container">
        <ArchiveButton
          id={id}
          isArchived={archived}
          onArchive={() => handleArchive(id, false)}
          onUnarchive={() => handleUnarchive(id, false)}
        />
      </div>
    ),
  }], []);

  return (
    <StyledTableContainer>
      <Flash
        isDismissible
        message={(
          <span>
            Training program {lastAction.type}d.&nbsp;
            {!lastAction.isUndo && <a href="#" onClick={handleUndo}>Undo?</a>}
          </span>
        )}
        showFlash={lastAction.id ? updateInterviewBatchMutation.isSuccess : false}
        type="success"
      />
      <Flash
        isDismissible
        message={updateInterviewBatchMutation.error?.message}
        onDismiss={updateInterviewBatchMutation.reset}
        showFlash={updateInterviewBatchMutation.isError}
        type="danger"
      />
      <Table
        data={interviewBatches}
        getRowLink={getRowLink}
        isPaginated
        layout="vertical"
        onPageNumberChange={onPageNumberChange}
        onShowArchivedChange={onShowArchivedChange}
        pageNumber={pageNumber}
        schema={columns}
        showArchived={showArchived}
        totalCount={totalCount}
      />
    </StyledTableContainer>
  );
};

export default InterviewBatchListTable;
